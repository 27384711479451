import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {IAuthState} from "../interfaces/store/states/IAuthState";
import {useSelector} from "react-redux";
import IAppState from "../interfaces/store/IAppState";
import helpers from "../helpers";

interface ILayoutRouteProps extends RouteProps {
    Layout: React.FunctionComponent<any>
    middleware?: null | "GUEST" | "AUTH"
}

const LayoutRoute: React.FunctionComponent<ILayoutRouteProps> = ({Layout, middleware = null, component: Component, ...rest}) => {
    const auth = useSelector<IAppState, IAuthState>(({auth}) => auth);

    if (!Component) return null;

    const Render = (props: RouteComponentProps<any>) => (
        <Layout>
            <Component {...props}/>
        </Layout>
    )

    return (
        <Route {...rest} render={props => {
            const token = localStorage.getItem(helpers.getTokenStr());
            const isAuthCond = (auth.isAuthenticated || token !== null);

            switch (middleware) {
                case "AUTH":
                    return !isAuthCond ? <Redirect to="/login"/> : Render(props);
                case "GUEST":
                    return isAuthCond ? <Redirect to="/"/> : Render(props);
                default:
                    return Render(props);
            }
        }}/>
    )
}

export default LayoutRoute