import * as types     from "../types/helpers/utils";
import moment         from "moment";
import IEvent         from "../interfaces/models/IEvent";
import { message }    from "antd";
import { VideoTypes } from "../types/entities/Event";

const backDateFormat = "YYYY-MM-DD HH:mm:ss A";

export const imgRegex = /[/.](gif|jpg|jpeg|tiff|png)$/i;

const getTokenStr: types.getTokenStrType = () => process.env.REACT_APP_AUTH_TOKEN_STR !== undefined ? process.env.REACT_APP_AUTH_TOKEN_STR : "";
const goTo: types.goToType = (history, path) => history.push(path);
const goBack: types.goBackType = history => history.goBack();
const isImage: types.isImageType = file => testRegex(imgRegex, file.type);

const getBase64: types.getBase64Type = file => (
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
)

const testRegex: types.testRegexType = (rgx, str) => rgx.test(str);

const eventIsStream: types.eventIsStreamType = (e, type) => type === "STREAM" && getEventTypeByDates(e) === "STREAM";

const getEventTypeByDates: types.getEventTypeByDateType = (e) => {
    if (e.schedules !== undefined && e.schedules.length > 0) {
        const sorted = sortSchedules(e.schedules);
        const d = sorted[0];

        if (d.date !== null) {
            return moment().add(1, 'h').isBefore(moment(d.date, backDateFormat)) ? "STREAM" : "VIDEO";
        } else {
            return "STREAM";
        }
    } else {
        return "STREAM";
    }
}

const sortSchedules: types.sortSchedulesType = (schedules, order = 'ASC') => {
    return schedules.sort((a, b) => +moment(a.date, backDateFormat) - +moment(b.date, backDateFormat));
}

const getYoutubeIdFromUrl: types.getYoutubeIdFromUrlType = url => {
    const x = url.replace(/([><])/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    if (x[2] !== undefined) {
        return x[2].split(/[^0-9a-z_-]/i)[0];
    } else {
        return x[0];
    }
}

const isYoutubeUrl: types.isYoutubeUrlType = url => {
    return url.indexOf('youtu') !== -1 && getYoutubeIdFromUrl(url) !== url;
}

const isBucketUrl: types.isBucketUrlType = url => {
    return url.indexOf('https://storage.googleapis.com/ese-training-resources') !== -1;
}

const onVideoDetail = (e: IEvent, type: VideoTypes, anchorRef: HTMLAnchorElement | null) => {
    if (type === "VIDEO") {
        if (anchorRef !== null && e.video_link !== null && e.video_link !== undefined) {
            //Bucket URL or Youtube Video
            if (isBucketUrl(e.video_link) || isYoutubeUrl(e.video_link)) {
                anchorRef.href = `${ window.location.href }video/${ e.id }`;
            } else {
                anchorRef.href = e.video_link;
            }

            anchorRef.click();
        } else {
            message.error("Ha ocurrido un inconveniente, no se puede redirigir al enlace asociado al evento.");
        }
    }
}

export default {
    getTokenStr,
    goTo,
    goBack,
    isImage,
    getBase64,
    eventIsStream,
    getEventTypeByDates,
    backDateFormat,
    sortSchedules,
    getYoutubeIdFromUrl,
    isYoutubeUrl,
    isBucketUrl,
    onVideoDetail
};