import React, { Fragment, useEffect, useState }                                                from "react";
import { Button, Layout, notification }                                                        from "antd";
import { useHistory, useParams }                                                               from "react-router-dom";
import helpers                                                                                 from "../../helpers";
import { AxiosResponse }                                                                       from "axios";
import { useDispatch }                                                                         from "react-redux";
import { setLoader }                                                                           from "../../store/actions/ui";
import "../../styles/views/Public/Video.less";
import EventsService
                                                                                               from "../../api/services/EventsService";
import IEvent
                                                                                               from "../../interfaces/models/IEvent";
import ReactPlayer                                                                             from 'react-player/lazy';
import { FileOutlined, LinkOutlined, PaperClipOutlined, PictureOutlined, VideoCameraOutlined } from "@ant-design/icons";

interface IParams {
    event_id: string
}

const Video: React.FunctionComponent = () => {
    const { event_id } = useParams<IParams>();
    const [event, setEvent] = useState<IEvent | null>(null);

    const dispatch = useDispatch();
    const history = useHistory();

    const getEvent = async (id: string) => {
        dispatch(setLoader(true));
        const res = await EventsService.get(id);

        if (helpers.isAxiosError(res)) {
            notification.error({
                placement: "bottomLeft",
                message: "Atención",
                description: "El evento al que deseas acceder no existe o a ocurrido un inconveniente al intentar obtener sus datos.",
            });

            history.push("/");
        } else {
            const { data } = res as AxiosResponse<IEvent>;
            setEvent(data);
        }
        dispatch(setLoader(false));
    }

    useEffect(() => {
        getEvent(event_id).then();
        // eslint-disable-next-line
    }, [event_id])

    useEffect(() => {
        if (event !== null) {
            if (event.video_link !== null) {
                if (!(helpers.isBucketUrl(event.video_link) || helpers.isYoutubeUrl(event.video_link))) {
                    notification.error({
                        placement: "bottomLeft",
                        message: "Atención",
                        description: "El evento no posee un vídeo asignado reproducible!",
                    });

                    history.push("/");
                }
            } else {
                notification.error({
                    placement: "bottomLeft",
                    message: "Atención",
                    description: "El evento no posee un vídeo asignado para ser reproducido!",
                });

                history.push("/");
            }
        }
        // eslint-disable-next-line
    }, [event]);

    return (
        <Layout id="video-component">
            { event !== null && event.video_link !== null && (
                <div className="event-cont">
                    <div className="embed-cont">
                        <ReactPlayer
                            controls
                            url={ event.video_link }
                            width="100%"
                            height="100%"
                        />
                    </div>
                    <div className="info">
                        <h1>{ event.name }</h1>
                        <p className="description">
                            { event.description }
                        </p>
                    </div>
                    { event.attachments !== undefined && (
                        <Fragment>
                            <h3><PaperClipOutlined/> Recursos</h3>
                            <div className="attachments">
                                { event.attachments?.map(a => (
                                    <Button
                                        icon={
                                            a.type?.code === "IMAGE" ?
                                                <PictureOutlined/> : (
                                                    a.type?.code === "LINK" ?
                                                        <LinkOutlined/> : (
                                                            a.type?.code === "FILE" ?
                                                                <FileOutlined/> : <VideoCameraOutlined/>
                                                        )
                                                )
                                        }
                                        href={ a.url }
                                        target="_blank"
                                        block>
                                        { a.name }
                                    </Button>
                                )) }
                            </div>
                        </Fragment>
                    ) }
                </div>
            ) }
        </Layout>
    );
}

export default Video;