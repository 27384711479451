import React, {useEffect} from "react";
import helpers from "../helpers";
import {Link} from 'rc-scroll-anim';
import {Menu as AntMenu} from "antd";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import IAppState from "../interfaces/store/IAppState";
import IMenuItem from "../interfaces/helpers/IMenuItem";
import "../styles/components/Menu.less";
import {SelectParam} from "antd/es/menu";
import {SetMenuItems} from "../store/actions/ui";

interface Props {
    mode?: 'vertical' | 'vertical-left' | 'vertical-right' | 'horizontal' | 'inline'
    className?: string
    items: Array<IMenuItem>
}

type onClickType = (i: IMenuItem) => void;

const Menu: React.FunctionComponent<Props> = ({mode = 'horizontal', className = '', items}) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector<IAppState, boolean>(({auth}) => auth.isAuthenticated);
    const selectedItems = useSelector<IAppState, string[]>(({ui}) => ui.selectedMenuItems);

    className += ' app-menu';

    const scrollLinkOnClick: onClickType = (i) => i.onClick !== undefined ? i.onClick(i) : {};

    const routerLinkOnClick: onClickType = (i) => {
        if (i.onClick !== undefined) {
            i.onClick(i);
        }

        if (i.path !== undefined) {
            helpers.goTo(history, i.path)
        }
    }

    const filterItems: (_items: Array<IMenuItem>) => Array<IMenuItem> = _items => _items
        .filter(i => i.type === "ALL" || (isAuthenticated ? i.type === "PRIVATE" : i.type === "PUBLIC"))
        .filter(i => i.onlyIn === undefined || i.onlyIn === location.pathname);

    const onSelect: (s: SelectParam) => void = (s) => {
        dispatch(SetMenuItems(s.selectedKeys));
    }

    const mo = new MutationObserver(ml => {
        ml.forEach(m => {
            const {target, attributeName} = m;
            const {parentElement} = target;

            if (attributeName === 'class' && target instanceof HTMLAnchorElement && parentElement !== null) {
                const id = target.getAttribute("id");
                if (target.classList.contains("active") && id !== null) {
                    dispatch(SetMenuItems([id]));
                } else if (checkActives() === 0) {
                    dispatch(SetMenuItems([]));
                }
            }
        })
    });

    const checkActives: () => number = () => {
        const el = document.querySelectorAll(".ant-menu-item > a");

        let actives = 0;
        el.forEach(e => {
            actives += e.classList.contains("active") ? 1 : 0;
        });

        return actives;
    }

    useEffect(() => {
        items.map(i => i.id).forEach(i => {
            const a = document.getElementById(i);
            if (a !== null) {
                mo.observe(a, {attributes: true, attributeFilter: ['class']});
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AntMenu
            mode={mode}
            selectedKeys={selectedItems}
            onSelect={onSelect}
            className={className}
        >
            {filterItems(items).map(i => {
                if (i.ref !== undefined) {
                    return <AntMenu.Item key={i.id} onClick={() => scrollLinkOnClick(i)}>
                        <Link
                            id={i.id}
                            component="a"
                            to={i.ref}
                            toShowHeight
                            offsetTop={90}
                            showHeightActive="30%"
                        >
                            {i.title}
                        </Link>
                    </AntMenu.Item>
                } else if (i.path !== undefined) {
                    return <AntMenu.Item key={i.id} onClick={() => routerLinkOnClick(i)}>
                        {i.title}
                    </AntMenu.Item>
                } else {
                    return <div/>;
                }
            })}
        </AntMenu>
    )
}

export default Menu;