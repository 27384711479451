import React, { Fragment, useEffect, useState } from 'react';
import { attachmentTypes }                      from "../types/attachments";
import { DataField }                            from "../types/DataField";
import { Button, Form, Input }                  from "antd";
import { DeleteOutlined }                       from "@ant-design/icons";
import { FormInstance }                         from "antd/es/form";

interface Props {
    _index: number
    field: DataField
    form: FormInstance
    removeFile: (index: number) => void
}

const FileTypeInput: React.FC<Props> = (props) => {
    const { field, removeFile, form, _index } = props;

    const [type, setType] = useState<attachmentTypes | null>(null);
    const [needsFile, setNeedsFile] = useState<boolean>(false);

    useEffect(() => {
        setType(form.getFieldValue(['attachments', field.name, 'type']) as unknown as attachmentTypes);
    }, [field.name, form, type, field.key]);

    useEffect(() => {
        if (type !== null) {
            setNeedsFile(["IMAGE", "FILE"].indexOf(type) !== -1);
        }
    }, [type]);

    return (
        <div className="attachment-container">
            <Form.Item
                { ...field }
                key={ `${ field.key }-name` }
                name={ [field.name, 'name'] }
                label={ `Renombrar ${ needsFile ? 'Archivo' : 'Link' }` }
                rules={ [
                    { required: true, message: "Debes ingresar un nombre" },
                ] }
                validateStatus={ form.getFieldError(["attachments", field.key, "name"]).length > 0 ? "error" : "" }
                help={ form.getFieldError(["attachments", field.key, "name"])[0] }
            >
                <Input
                    disabled={ form.getFieldValue(['attachments', field.name, 'disabled']) ?? false }
                />
            </Form.Item>
            <div className="attachment-info">
                <Button
                    danger type="primary"
                    onClick={ () => removeFile(_index) }
                    icon={ <DeleteOutlined/> }
                    disabled={ form.getFieldValue(['attachments', field.name, 'disabled']) ?? false }
                />
                { type !== null && (
                    <Fragment>
                        <Form.Item
                            name={ [field.name, 'file'] }
                            style={ { display: "none" } }
                            noStyle
                        >
                            <Input type={ "hidden" }/>
                        </Form.Item>

                        { needsFile && (
                            <Form.Item
                                // name={ [field.name, 'file', 'name'] }
                                validateStatus={ form.getFieldError(["attachments", field.key, "file"]).length > 0 ? "error" : "" }
                                help={ form.getFieldError(["attachments", field.key, "file"])[0] }
                            >
                                <Input
                                    disabled
                                    type={ "text" }
                                    value={ form.getFieldValue(['attachments', field.name, 'file', 'name']) }
                                />
                            </Form.Item>
                        ) }

                        { !needsFile && (
                            <Form.Item
                                name={ [field.name, 'url'] }
                                rules={ [
                                    { required: true, message: "Debes ingresar un enlace" },
                                ] }
                                validateStatus={ form.getFieldError(["attachments", field.key, "url"]).length > 0 ? "error" : "" }
                                help={ form.getFieldError(["attachments", field.key, "url"])[0] }
                            >
                                <Input
                                    disabled={ form.getFieldValue(['attachments', field.name, 'disabled']) ?? false }
                                    type={ "text" }
                                    placeholder={ `Pega la URL del ${ type === "VIDEO" ? "video" : "sitio" }` }
                                />
                            </Form.Item>
                        ) }
                    </Fragment>
                ) }

            </div>
        </div>
    );
}

export default FileTypeInput;