import IUIState       from "../../interfaces/store/states/IUIState";
import { IUIActions } from "../../interfaces/store/actions/IUIActions";

const initialState: IUIState = {
    loader: false,
    selectedMenuItems: [],
    anchorRef: null,
    defaultEvent: null,
    hidePendingEvents: false
}

const uiReducer = (state = initialState, action: IUIActions) => {
    switch (action.type) {
        case "SET_LOADER_STATUS":
            return {
                ...state,
                loader: action.value,
            }
        case "SET_SELECTED_MENU_ITEMS":
            return {
                ...state,
                selectedMenuItems: action.value
            }
        case "SET_ANCHOR_REF":
            return {
                ...state,
                anchorRef: action.value
            }
        case "SET_DEFAULT_EVENT":
            return {
                ...state,
                defaultEvent: action.value
            }
        case "SET_HIDE_PENDING_EVENTS":
            return {
                ...state,
                hidePendingEvents: action.value
            }
        default:
            return state;
    }
}

export default uiReducer;