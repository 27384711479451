import React, {useEffect, useState} from "react";
import {Layout, notification, Button} from "antd";
import {useHistory, useParams, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../store/actions";
import helpers from "../../helpers";
import AuthService from "../../api/services/AuthService";
import IAppState from "../../interfaces/store/IAppState";
import CreateUserForm from "../../components/Home/CreateUserForm";

interface IParams {
    token?: string
}

const Login: React.FunctionComponent = () => {
    const {token} = useParams<IParams>();
    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const queryToken = query.get("token");
    const dispatch = useDispatch();

    const [showForm, setShowForm] = useState(false);

    const [error, setError] = useState<string | null>(null);

    const fetchError = useSelector<IAppState, string | null>(({auth}) => auth.error);

    useEffect(() => {
        dispatch(actions.ui.setLoader(true));
    }, [dispatch]);

    useEffect(() => {
        if (fetchError !== null) {
            setError(fetchError);
        }
    }, [fetchError])

    useEffect(() => {
        if (token !== undefined) {
            if (["not-found-user", "error"].indexOf(token) === -1) {
                dispatch(actions.auth.setToken(token));
            } else {
                if (queryToken !== null) {
                    setShowForm(true);
                } else {
                    setError(token);
                }
            }

            dispatch(actions.ui.setLoader(false));
        } else {
            const getLoginUrl = async () => {
                const url = await AuthService.getLoginUrl();

                if (!helpers.isAxiosError(url) && typeof url === "string") {
                    window.location.href = url;
                } else {
                    dispatch(actions.ui.setLoader(false));
                    helpers.goBack(history);
                    notification.error({
                        message: "Ha ocurrido un error",
                        description: "No se pudo acceder a los datos del servidor, inténtalo más tarde...",
                        placement: "bottomLeft",
                    });
                }
            }

            getLoginUrl().then();
        }
    }, [dispatch, history, token, queryToken]);

    return (
        <Layout id="login-component">
            {error !== null && (
                <div>
                    <span>{error}</span>
                    <Button type="primary" size="large" onClick={() => history.push("/")}>Ir a inicio</Button>
                </div>
            )}

            {showForm && queryToken !== null && <CreateUserForm token={queryToken}/>}
        </Layout>
    )
}

export default Login;