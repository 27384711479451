import {Button, Drawer, Layout} from "antd";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import logo from "../../assets/logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp, faBars} from "@fortawesome/free-solid-svg-icons";
import Menu from "../../components/Menu";
import getMenuItems from "../../components/Admin/MenuItems";
import "../../styles/layouts/General.less";

const {Header, Content} = Layout;

const AdminLayout: React.FunctionComponent = ({children}) => {
    const dispatch = useDispatch();

    const menuItems = getMenuItems(dispatch);
    const [drawer, setDrawer] = useState(false);

    const OnDrawerClose = () => setDrawer(false);

    return (
        <Layout className="general-app-layout" id="public-app-layout">
            <Drawer
                visible={drawer}
                closable={false}
                placement="top"
                onClose={OnDrawerClose}
                width="100vw"
                height="100vh"
                title={(
                    <div className="sidenav-logo-title">
                        <img src={logo} alt="ESE Logo"/>
                        <Button onClick={OnDrawerClose} type="link"><FontAwesomeIcon icon={faAngleUp}/></Button>
                    </div>
                )}
            >
                <Menu items={menuItems} mode="vertical"/>
            </Drawer>

            <Header className="general-app-header" id="public-header">
                <div className="header-logo">
                    <div className="logo-cont">
                        <img src={logo} alt="ESE Logo"/>
                    </div>
                    <Button
                        className="show-sm"
                        type="link"
                        id="btnShowSidenav"
                        onClick={() => setDrawer(true)}
                    >
                        <FontAwesomeIcon size="1x" icon={faBars}/>
                    </Button>
                </div>

                <Menu items={menuItems} mode="horizontal" className="hide-sm"/>
            </Header>

            <Content id="main-app-container">
                {children}
            </Content>
        </Layout>
    )
}

export default AdminLayout;