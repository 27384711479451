import React, {useEffect, useState} from 'react';
import Router from "./routes/Router";
import moment from "moment";
import "moment/locale/es";
import Loader from "./components/Loader";
import {useDispatch, useSelector} from "react-redux";
import IAppState from "./interfaces/store/IAppState";
import {IAuthState} from "./interfaces/store/states/IAuthState";
import {SetAnchorRef, setLoader} from "./store/actions/ui";
import {getMe} from "./store/actions/auth";
import helpers from "./helpers";
import IUser from "./interfaces/models/IUser";

function App() {
    const [domLoading, setDomLoading] = useState(true);
    const uiLoader = useSelector<IAppState, boolean>(state => state.ui.loader);
    const auth = useSelector<IAppState, IAuthState>(state => state.auth);
    const dispatch = useDispatch();

    const prevFetchingMe = helpers.usePrevious<boolean>(auth.fetchingMe);
    const prevMe = helpers.usePrevious<IUser | null>(auth.me);

    const onLoadListener = () => setDomLoading(false);

    useEffect(() => {
        moment.locale("es");
        window.addEventListener("load", onLoadListener);

        return () => {
            document.removeEventListener("load", onLoadListener);
        }
    }, []);

    useEffect(() => {
        const storeToken = localStorage.getItem(helpers.getTokenStr());
        if (auth.token !== null && storeToken !== null && !auth.isAuthenticated && auth.me !== prevMe) {
            dispatch(getMe());
        }
    }, [auth.isAuthenticated, auth.me, auth.token, dispatch, prevMe]);

    useEffect(() => {
        if (prevFetchingMe !== undefined && auth.fetchingMe !== prevFetchingMe) {
            dispatch(setLoader(auth.fetchingMe));
        }
    }, [auth.fetchingMe, dispatch, prevFetchingMe]);

    return (
        <Loader isLoading={domLoading || uiLoader}>
            <a
                href="/#"
                ref={instance => dispatch(SetAnchorRef(instance))}
                className="event-anchor"
                target="_blank"
                rel="noopener noreferrer"
            >
                _
            </a>
            <Router/>
        </Loader>
    );
}

export default App;
