import { RcFile }       from "antd/lib/upload/interface";
import { message }      from "antd";
import { FormInstance } from "antd/lib/form";
import helpers          from "../../../../helpers";

type callback = (src: string) => any;
type handleFilesType = (form: FormInstance, file: RcFile, files: RcFile[], callback: callback) => boolean;

const handleFiles: handleFilesType = (form, file, files, callback: callback) => {
    const image = files[files.length - 1];

    if (image !== undefined && image !== null) {
        if (!helpers.isImage(image)) {
            message.error("Debes ingresar una imagen");
            return false
        }

        (async () => {
            form.setFieldsValue({ image });
            const src = await helpers.getBase64(image);

            if (typeof src === "string") {
                callback(src);
            } else {
                message.error("Ocurrió un error");
            }
        })();
    } else {
        message.error("Ocurrió un error");
    }

    return false;
}

export default handleFiles;