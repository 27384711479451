import { Alert, Button, DatePicker, Form, Input, Select, Switch, TimePicker, Upload } from "antd";
import React, { useEffect, useState }                                                 from "react";
import { useForm }                                                                    from "antd/es/form/Form";
import helpers                                                                        from "../../helpers";
import locale
                                                                                      from "antd/es/date-picker/locale/es_ES";
import moment                                                                         from "moment";
import EventsService
                                                                                      from "../../api/services/EventsService";
import { AxiosError, AxiosResponse }                                                  from "axios";
import IWebinar
                                                                                      from "../../interfaces/models/IWebinar";
import QueueAnim                                                                      from "rc-queue-anim";
import { MinusCircleOutlined, PlusOutlined, UploadOutlined }                          from "@ant-design/icons";
import handleFinish
                                                                                      from "./EventForm/functions/handleFinish";
import handleFiles
                                                                                      from "./EventForm/functions/handleFiles";
import AttachmentActions
                                                                                      from "./EventForm/components/AttachmentActions";
import normFile
                                                                                      from "./EventForm/functions/normFile";
import rules                                                                          from "./EventForm/rules";

interface Props {
    onSuccess: () => any
}

const NewEventForm: React.FunctionComponent<Props> = ({ onSuccess }) => {
    const [form] = useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const [webinars, setWebinars] = useState<IWebinar[] | null>(null);
    const [isWebinar, setIsWebinar] = useState(false);
    const [loadingWebinars, setLoadingWebinars] = useState(false);
    const [getWebinarsError, setGetWebinarsError] = useState<string | null>(null);

    const [imgSrc, setImgSrc] = useState<string | null>(null);
    const [isVisible, setIsVisible] = useState(true);

    const getWebinars = async () => {
        setLoadingWebinars(true);
        const res = await EventsService.getWebinars(true);

        if (helpers.isAxiosError(res)) {
            const err = res as AxiosError;

            helpers.handleAxiosError(err, {
                default: () => setGetWebinarsError("No se pudieron obtener las Webinars, inténtalo más tarde")
            })
        } else {
            const { data: webinars } = (res as AxiosResponse<IWebinar[]>);
            setWebinars(webinars);
        }
        setLoadingWebinars(false);
    }

    const toggleAssociateWebinar = () => {
        setIsWebinar(prevState => !prevState);
    }

    const fillValuesWithWebinar = (webinar_id: number) => {
        if (webinars !== null) {
            const webinar = webinars.find(({ webinar_id: id }) => id === webinar_id);

            if (webinar !== undefined) {
                form.setFieldsValue({
                    name: webinar.name,
                    description: webinar.description,
                    link: webinar.direct_live_room_url,
                    schedules: webinar.schedules.map(s => ({
                        date: moment(s.date, "YYYY-MM-DD HH:mm"),
                        time: moment(s.date, "YYYY-MM-DD HH:mm"),
                    }))
                });

                const date = webinar.schedules.map(s => moment(s.date, "YYYY-MM-DD HH:mm"))[0];

                if (date !== undefined) {
                    form.setFieldsValue({ date, time: date });
                }
            }
        }
    }

    useEffect(() => {
        if (webinars === null) {
            getWebinars().then();
        }
    }, [webinars])

    useEffect(() => {
        form.resetFields();
        setImgSrc(null);
    }, [form, isWebinar])

    return (
        <Form
            form={ form }
            layout="vertical"
            initialValues={ {
                name: "",
                description: "",
                link: "",
                visible: true,
                image: null,
                webinar_id: null,
                schedules: [],
                attachments: [],
            } }
            name="frmEvent"
            onFinish={ () => handleFinish(form, setLoading, isWebinar, () => {
                form.resetFields();
                setImgSrc(null);
                onSuccess()
            }) }
            scrollToFirstError
            hideRequiredMark
        >
            <div className="title-cont">
                <h1>Crear Evento</h1>

                <div className="switch-wrap">
                    <span className={ isVisible ? 'active' : '' }>Visible</span>

                    <Form.Item name="visible" valuePropName="checked" noStyle>
                        <Switch onChange={ setIsVisible }/>
                    </Form.Item>
                </div>
            </div>

            <div className="sync-container">
                <h1>¿Estas usando WebinarJam? Sincroniza tu evento</h1>
                <Button
                    size="large"
                    loading={ loadingWebinars }
                    disabled={ getWebinarsError !== null }
                    onClick={ toggleAssociateWebinar }
                    danger={ isWebinar }
                >
                    { isWebinar ? 'Cancelar' : 'Sincronizar' }
                </Button>
            </div>

            { getWebinarsError !== null && (
                <QueueAnim type="bottom">
                    <Alert type="error" showIcon message={ getWebinarsError }/>
                </QueueAnim>
            ) }

            { isWebinar && webinars !== null && (
                <Form.Item
                    key="item"
                    label="Webinar a asignar"
                    name="webinar_id"
                    rules={ isWebinar ? [{
                        required: true,
                        message: "Debes seleccionar una Webinar para asignar"
                    }] : [] }
                >
                    <Select
                        onSelect={ val => fillValuesWithWebinar(val as number) }
                        options={ webinars.map(w => ({ label: w.name, value: w.webinar_id })) }
                    />
                </Form.Item>
            ) }

            <hr/>

            <Form.Item
                label="Nombre"
                name="name"
                rules={ !isWebinar ? rules.name : [] }
            >
                <Input disabled={ isWebinar } placeholder="¿Cual es el nombre del evento?"/>
            </Form.Item>

            <Form.List name="schedules">
                { (fields, { add, remove }) => (
                    <Form.Item
                        label={
                            <div>
                                <span>Horarios</span>
                                <Button disabled={ isWebinar } icon={ <PlusOutlined/> } onClick={ () => add() }/>
                            </div>
                        }
                    >
                        { form.getFieldError("schedules").length > 0 && (
                            <Alert type="error" showIcon message={ form.getFieldError("schedules")[0] }/>
                        ) }

                        { fields.map(field => (
                            <Form.Item
                                key={ field.key }
                                className="starts_at_cont"
                                validateStatus={ form.getFieldError(["schedules", field.key, "full_date"]).length > 0 ? "error" : "" }
                                help={ form.getFieldError(["schedules", field.key, "full_date"])[0] }
                            >
                                <Form.Item name={ [field.name, 'full_date'] } style={ { display: "none" } }>
                                    <Input type="hidden"/>
                                </Form.Item>

                                <Form.Item
                                    { ...field }
                                    key={ `${ field.key }-date` }
                                    name={ [field.name, 'date'] }
                                    rules={ [{ required: true, message: "Debes ingresar una fecha" }] }
                                >
                                    <DatePicker
                                        disabled={ isWebinar }
                                        locale={ locale }
                                        format="DD-MM-yyyy"
                                        placeholder="DD-MM-YYYY"
                                    />
                                </Form.Item>

                                <Form.Item
                                    { ...field }
                                    key={ `${ field.key }-time` }
                                    name={ [field.name, 'time'] }
                                    rules={ [{ required: true, message: "Debes ingresar una hora" }] }
                                >
                                    <TimePicker
                                        disabled={ isWebinar }
                                        locale={ locale }
                                        use12Hours
                                        format="hh:mm A"
                                        placeholder="00:00 AM"
                                    />
                                </Form.Item>

                                <Button
                                    danger
                                    disabled={ isWebinar }
                                    icon={ <MinusCircleOutlined/> }
                                    onClick={ () => remove(field.name) }
                                />
                            </Form.Item>
                        )) }
                    </Form.Item>
                ) }
            </Form.List>

            <Form.Item
                label="Descripción"
                name="description"
                rules={ !isWebinar ? rules.description : [] }
            >
                <Input.TextArea disabled={ isWebinar } placeholder="Escribe una descripción de que trata el evento"/>
            </Form.Item>

            <Form.Item label="Imagen">
                <Form.Item
                    name="image"
                    rules={ rules.image }
                    valuePropName="fileList"
                    getValueFromEvent={ normFile }
                    noStyle
                >
                    <Upload.Dragger
                        accept="image/*"
                        name="image"
                        beforeUpload={ (file, FileList) => handleFiles(form, file, FileList, src => setImgSrc(src)) }
                        showUploadList={ false }
                    >
                        { imgSrc !== null && <img className="custom-preview-img" src={ imgSrc } alt="Preview"/> }
                        { imgSrc === null && (
                            <div>
                                <p className="ant-upload-drag-icon">
                                    <UploadOutlined/>
                                </p>
                                <p className="ant-upload-text">Arrastra o seleccionar una imagen para el evento</p>
                            </div>
                        ) }
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item>

            <Form.Item
                label="Link"
                name="link"
                rules={ !isWebinar ? rules.link : [] }
            >
                <Input
                    disabled={ isWebinar }
                    placeholder="Pega la URL del evento. Puede ser un link de Facebook u otra platafoma"
                />
            </Form.Item>

            <Form.Item
                label="Archivos de apoyo"
                noStyle
                // help={<span>Máximo 5 archivos. 25mb en total</span>}
            >
                <Form.List
                    name="attachments"
                >
                    { (fields, operation) =>
                        <AttachmentActions form={ form } operation={ operation } fields={ fields } name="files"/>
                    }
                </Form.List>
            </Form.Item>

            <Form.Item className="form-item-submit-button-cont">
                <Button
                    icon={ <UploadOutlined/> }
                    loading={ loading }
                    type="primary"
                    htmlType="submit"
                    size="large"
                >
                    Publicar
                </Button>
            </Form.Item>
        </Form>
    )
}

export default NewEventForm;