import IApiResponse from "../../interfaces/api/IApiResponse";
import Service from "../Service";
import config from "../client-configurations/AppServiceConfig";
import {AxiosError, AxiosResponse} from "axios";
import IUser from "../../interfaces/models/IUser";
import ISSOUser from "../../interfaces/models/ISSOUser";

export interface ICreateUserResponse {
    status: boolean
    token?: string | null
}

class AuthService extends Service<IApiResponse> {
    public async getLoginUrl(): Promise<string | AxiosError> {
        try {
            const res = await this.http.get<string>(`${this.entityName}/url`);
            return res.data;
        } catch (ex) {
            return ex as AxiosError;
        }
    }

    public async getMe(): Promise<IUser | AxiosError> {
        try {
            const res = await this.http.get<IUser>(`${this.entityName}/me`);
            return res.data;
        } catch (ex) {
            return ex as AxiosError;
        }
    }

    public async logOut(): Promise<IApiResponse<boolean> | AxiosError<boolean>> {
        try {
            const res: AxiosResponse<IApiResponse<boolean>> = await this.http.post<IApiResponse<boolean>>(`${this.entityName}/logout`);
            return res.data;
        } catch (ex) {
            return ex as AxiosError;
        }
    }

    public async getSSOUser(token: string): Promise<AxiosResponse<ISSOUser> | AxiosError> {
        try {
            return await this.http.get<ISSOUser>(`${this.entityName}/sso_user`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        } catch (ex) {
            return ex as AxiosError;
        }
    }

    public async insertUser(data: object, token: string): Promise<AxiosResponse<ICreateUserResponse> | AxiosError> {
        try {
            return await this.http.post<ICreateUserResponse>(`${this.entityName}/sso_user`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        } catch (ex) {
            return ex as AxiosError;
        }
    }
}

export default new AuthService("auth", config);