import helpers     from "../../../helpers";
import { RcFile }  from "antd/es/upload";
import { Rule }    from "antd/es/form";
import IFormFields from "./types/IFormFields";

const rules: { [K in keyof IFormFields]?: Rule[] } = {
    name: [
        { required: true, message: "Debes ingresar un nombre del evento" },
    ],
    image: [
        {
            validator: (_, value: RcFile[]) => {
                if (value !== null) {
                    const file = value[value.length - 1];
                    if (file === undefined || file === null) return Promise.reject("Debes seleccionar una imagen para el evento");

                    if (helpers.isImage(file)) {
                        return Promise.resolve();
                    } else {
                        return Promise.reject("Debes seleccionar una imagen")
                    }
                } else {
                    return Promise.resolve();
                }
            }
        }
    ],
    stream_link: [
        { required: true, message: "Debes ingresar una enlace de stream para el evento" },
    ],
    video_link: [
        { required: true, message: "Debes ingresar una enlace de vídeo para el evento" },
    ],
    description: [
        { required: true, message: "Debes ingresar una descripción para el evento" },
    ],
}

export default rules;