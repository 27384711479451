import React, { useEffect, useState }  from "react";
import { useLazyQuery }                from "react-apollo";
import IPaginatedGraphData             from "../../interfaces/helpers/IPaginatedGraphData";
import IEvent                          from "../../interfaces/models/IEvent";
import { OverPack }                    from "rc-scroll-anim/lib";
import QueueAnim                       from "rc-queue-anim";
import EventCard                       from "../EventCard";
import { Alert, Pagination, Skeleton } from "antd";
import GraphErrorAlert                 from "../GraphErrorAlert";
import { DocumentNode }                from "graphql";
import EventDetails                    from "./EventDetails";
import { useDispatch, useSelector }    from "react-redux";
import IAppState                       from "../../interfaces/store/IAppState";
import helpers                         from "../../helpers";
import { SetHidePendingEvents }        from "../../store/actions/ui";

type Sizes = 'sm' | 'md';

interface Props {
    perPage: number
    query: DocumentNode
    size?: Sizes
    title: string
    type?: "STREAM" | "VIDEO"
}

const EventsContainer: React.FunctionComponent<Props> = ({ perPage, query, size = 'md', title, type = "STREAM" }) => {
    const dispatch = useDispatch();
    const [getEvents, { loading, data, error }] = useLazyQuery<{ events: IPaginatedGraphData<IEvent> }>(query, {
        fetchPolicy: "no-cache"
    });

    const [events, setEvents] = useState<IPaginatedGraphData<IEvent>>({ data: [], paginatorInfo: { total: 0 } });
    const [page, setPage] = useState(1);

    const anchorRef = useSelector<IAppState, HTMLAnchorElement | null>(({ ui }) => ui.anchorRef);

    useEffect(() => {
        if (!loading && error === undefined && data?.events !== undefined) {
            setEvents(data?.events);

            if (data?.events.paginatorInfo.total === 0) {
                dispatch(SetHidePendingEvents(true));
            }
        } else {
            dispatch(SetHidePendingEvents(true));
        }
    }, [loading, data, error, dispatch]);

    useEffect(() => {
        getEvents({
            variables: { page, perPage }
        });
    }, [getEvents, page, perPage]);


    return (
        <div className="aux-events-cont">
            {/*
      // @ts-ignore */ }
            <OverPack always={ false } className="scroll-over-pack-cont">
                <QueueAnim
                    key="aux-title-queue-anim"
                    type="bottom"
                >
                    <h1 key="title" className="section-title">{ title }</h1>
                </QueueAnim>

                <div className="events-cont">
                    { !loading && error === undefined && (
                        <QueueAnim style={ { width: "100%" } } type="bottom" duration={ 600 }>
                            { events.data.map(e => <span key={ e.id }>
                                <EventCard
                                    type={ type }
                                    event={ e }
                                    size={ size }
                                    mdlDetails={ EventDetails }
                                    closable={ false }
                                    onDetails={ type === "VIDEO" ? () => helpers.onVideoDetail(e, type, anchorRef) : undefined }
                                />
                            </span>) }
                        </QueueAnim>
                    ) }

                    { [...Array(perPage).keys()].map(i =>
                        <Skeleton
                            active
                            key={ i }
                            loading={ loading }
                            paragraph={ { rows: 11 } }
                        />
                    ) }

                    { !loading && events.data.length === 0 && error === undefined && (
                        <Alert
                            message="No se encontraron resultados"
                            description="No existen registros con los parámetros establecidos..."
                            showIcon
                            type="error"
                        />
                    ) }

                    { !loading && error !== undefined && (
                        <GraphErrorAlert error={ error }/>
                    ) }

                    <div className="pagination-cont">
                        <Pagination
                            hideOnSinglePage
                            current={ page }
                            onChange={ page => setPage(page) }
                            total={ events.paginatorInfo.total }
                            pageSize={ perPage }
                        />
                    </div>
                </div>
            </OverPack>
        </div>
    )
}

export default EventsContainer;