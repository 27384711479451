import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {SetDefaultEvent, setLoader} from "../../store/actions/ui";
import gql from "graphql-tag";
import IEvent from "../../interfaces/models/IEvent";
import {useQuery} from "react-apollo-hooks";
import {message} from "antd";
import {useHistory, useParams} from "react-router-dom";

const GET_EVENT = gql`
    query GetEvent($id: String) {
        event(id: $id) {
            id
            name
            description
            thumbnail
            video_link
            webinar_id
            schedules {
                date
            }
            user {
                name
                last_name
            }
        }
    }
`;

const SetEvent: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const {id} = useParams<{ id?: string }>();
    const history = useHistory();

    const {data, loading, error} = useQuery<{ event: IEvent }>(GET_EVENT, {
        variables: {id}
    });

    useEffect(() => {
        dispatch(setLoader(true));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
            if (!loading && data?.event !== undefined) {
                if (error !== undefined) {
                    message.error({
                        message: "Atención",
                        content: "El evento al que deseas acceder no existe",
                    });
                } else {
                    dispatch(SetDefaultEvent(data.event));
                }

                dispatch(setLoader(false));
                history.push("/")
            }

            // eslint-disable-next-line
        }, [data, error, loading]
    );

    return (
        <div/>
    )
}

export default SetEvent;