import {ThunkAction, ThunkDispatch} from "redux-thunk";
import IAppState from "../../interfaces/store/IAppState";
import {IAuthActions} from "../../interfaces/store/actions/IAuthActions";
import * as types from "./constants/auth";
import AuthService from "../../api/services/AuthService";
import helpers from "../../helpers";
import {AxiosError} from "axios";
import IUser from "../../interfaces/models/IUser";
import {setLoader} from "./ui";
import {IUIActions} from "../../interfaces/store/actions/IUIActions";
import {Action} from "redux";

interface IAuthAction<R, E = null, A extends Action = IAuthActions> extends ThunkAction<R, IAppState, E, A> {
}

export const setToken = (token: string, callback: (() => void) | null = null): IAuthAction<void> => (
    async (dispatch) => {
        localStorage.setItem(helpers.getTokenStr(), token);
        dispatch({type: types.SET_TOKEN, token});
        dispatch(getMe());

        if (typeof callback === "function") {
            callback();
        }
    }
)

export const getMe = (): IAuthAction<void> => {
    return async (dispatch: ThunkDispatch<IAppState, {}, IAuthActions>, getState) => {
        const {isAuthenticated} = getState().auth;

        if (!isAuthenticated) {
            dispatch({type: types.GET_ME});
            const user = await AuthService.getMe();

            if (helpers.isAxiosError(user)) {
                const err = user as AxiosError;
                dispatch({type: types.SET_ERROR, message: err.message});
            } else {
                dispatch({type: types.SET_USER, payload: user as IUser});
            }
        }
    }
}

export const logOut = (): IAuthAction<void, null, IAuthActions | IUIActions> => (
    async dispatch => {
        dispatch(setLoader(true));
        await AuthService.logOut();
        localStorage.removeItem(helpers.getTokenStr());
        dispatch({type: types.LOG_OUT});
        dispatch(setLoader(false));
    }
)