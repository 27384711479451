import {logOut} from "../../store/actions/auth";
import {Dispatch} from "redux";
import IMenuItem from "../../interfaces/helpers/IMenuItem";

type getMenuItemsType = (dispatch: Dispatch<any>) => Array<IMenuItem>;

const getMenuItems: getMenuItemsType = (dispatch) => [
    {
        id: "/home",
        title: "Inicio",
        path: "/",
        type: "PRIVATE"
    },
    {
        id: "logout-menu-link",
        title: "Cerrar sesión",
        path: "",
        type: "PRIVATE",
        onClick: () => dispatch(logOut())
    }
]

export default getMenuItems;