import React, { useEffect, useState }   from "react";
import IEvent                           from "../interfaces/models/IEvent";
import "../styles/components/EventCard.less";
import { Button, Modal, Tooltip }       from "antd";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import { faFilm, faVideo }              from "@fortawesome/free-solid-svg-icons";
import moment, { Moment }               from "moment";
import { ButtonType }                   from "antd/lib/button";
import { CardDetailsProps, VideoTypes } from "../types/entities/Event";
import { CardSizes }                    from "../types/components/EventCard";
import helpers                          from "../helpers";
import { PaperClipOutlined }            from "@ant-design/icons";

interface Props {
    event: IEvent
    type?: VideoTypes
    size?: CardSizes
    onDetails?: (e: IEvent) => any
    enableDetails?: boolean
    mdlDetails?: React.FunctionComponent<CardDetailsProps>
    closable?: boolean
    detailsText?: {
        stream: string
        video: string
    }
    mdlDetailsClassName?: string
    disableWrapperClass?: boolean
    onSuccess?: () => any
    defaultOpen?: boolean
    cardClassName?: string
}

interface formattedDateReturn {
    object: Moment | null,
    formatted: string
}

const EventCard: React.FunctionComponent<Props> = (
    {
        event, type = 'STREAM', size = 'md', onDetails,
        enableDetails = true, mdlDetails, closable = true,
        detailsText = {
            stream: "Ver detalles",
            video: "Ver vídeo"
        },
        mdlDetailsClassName,
        disableWrapperClass = false,
        onSuccess,
        defaultOpen = false,
        cardClassName = ""
    }
) => {

    const [closestDate, setClosestDate] = useState<formattedDateReturn>({
        formatted: "-",
        object: null
    });

    const [mdlVisible, setMdlVisible] = useState<boolean>(false);

    useEffect(() => {
        if (defaultOpen) {
            setMdlVisible(true);
        }
    }, [defaultOpen]);

    useEffect(() => {
        if (event.schedules !== undefined) {
            const now = moment();
            const sorted = event.schedules.filter(s => s.date !== null).sort((a, b) => {
                const a_m = moment(a.date);
                const b_m = moment(b.date);

                const a_ = Math.abs(now.diff(a_m, 'milliseconds'));
                const b_ = Math.abs(now.diff(b_m, 'milliseconds'));

                if (a_ === b_) return 0;
                return a_ > b_ ? 1 : -1;

            });

            if (sorted.length >= 1 && sorted[0].date !== null) {
                setClosestDate(formatDate(sorted[0].date, size));
            } else {
                setClosestDate({
                    formatted: "-",
                    object: null
                });
            }
        } else {
            setClosestDate({
                formatted: "-",
                object: null
            });
        }
    }, [event, size]);


    const formatDate: (date: string, size?: CardSizes) => { object: Moment, formatted: string } = (date, size = "md") => {
        const format = `MMM DD${ size === "sm" ? "" : " hh:mm a" }`;
        const moment_obj = moment(date, helpers.backDateFormat);

        return {
            formatted: moment_obj.format(format),
            object: moment_obj
        }
    }

    const buttonType: (type: CardSizes) => ButtonType = (type) => {
        switch (type) {
            case "lg":
                return "default";
            case "md":
                return "primary";
            case "sm":
                return "link";
        }
    }

    const onDetailsClick = () => {
        if (onDetails === undefined) {
            setMdlVisible(true);
        } else {
            onDetails(event);
        }
    }

    const onMdlClose = () => setMdlVisible(false)

    return (
        <div className={ `event-card-cont ${ size } ${ cardClassName }` }>
            <div className="thumbnail" style={ { backgroundImage: `url(${ event.thumbnail })` } }>
                <Tooltip title={ type === "STREAM" ? "Directo" : "Video" }>
                    <div className="icon">
                        <FontAwesomeIcon icon={ helpers.eventIsStream(event, type) ? faVideo : faFilm }/>
                    </div>
                </Tooltip>
            </div>

            <div className="info">
                <div className="info-wrapper">
                    <p className="time">{ closestDate.formatted }</p>
                    <p className="name">{ event.name }</p>
                    <p className="description">{ event.description }</p>
                </div>

                { size === "sm" && (event?.attachments?.length ?? 0) > 0 && (
                    <div className="attachments-info">
                        <PaperClipOutlined/>
                        Archivos de apoyo
                    </div>
                ) }

                { enableDetails && (
                    <Button
                        size="large"
                        type={ buttonType(size) }
                        onClick={ onDetailsClick }
                    >
                        { type === "STREAM" ? detailsText?.stream : detailsText?.video }
                    </Button>
                ) }
            </div>

            { enableDetails && (
                <Modal
                    visible={ mdlVisible }
                    onCancel={ onMdlClose }
                    wrapClassName={ !disableWrapperClass ? "__event-details-mdl" : "" }
                    className={ mdlDetailsClassName }
                    centered={ true }
                    closable={ closable }
                    footer={ null }
                >
                    { mdlDetails !== undefined && mdlDetails({
                        event,
                        onDetails: onDetails,
                        closeMdl: () => setMdlVisible(false),
                        onSuccess,
                        closestDate: closestDate.object
                    }) }
                </Modal>
            ) }

        </div>
    )
}

export default EventCard;