import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import store from "../store";
import helpers from "../helpers";
import {logOut} from "../store/actions/auth";

const responseHandler = (response: AxiosResponse) => response;

const errorHandler = (error: AxiosError) => {
    if (helpers.isAxiosError(error)) {
        if (error.response !== undefined) {
            const {status, config} = error.response;

            const {getState, dispatch} = store;
            const {isAuthenticated} = getState().auth;
            const token = localStorage.getItem(helpers.getTokenStr());

            if (status === 401 && config.url?.indexOf("logout") === -1 && (isAuthenticated || token !== null)) {
                // @ts-ignore
                dispatch(logOut());
            }
        }
    }

    return error;
}

function getAxiosInstance(config: AxiosRequestConfig): AxiosInstance {
    const axiosInstance = axios.create(config);

    axiosInstance.interceptors.request.use((config) => {
        const {getState} = store;
        const {token} = getState().auth;

        if ((config.headers.Authorization === undefined || config.headers.Authorization !== token) && token !== null) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    })

    axiosInstance.interceptors.response.use(responseHandler, errorHandler);

    return axiosInstance;
}

export default getAxiosInstance;