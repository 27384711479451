import {IAuthState} from "../../interfaces/store/states/IAuthState";
import helpers from "../../helpers";
import {IAuthActions} from "../../interfaces/store/actions/IAuthActions";

const initialState: IAuthState = {
    isAuthenticated: false,
    me: null,
    fetchingMe: false,
    token: localStorage.getItem(helpers.getTokenStr()) || null,
    error: null,
}

const AuthReducer = (state = initialState, action: IAuthActions) => {
    switch (action.type) {
        case "SET_TOKEN":
            return {
                ...state,
                token: action.token,
            }
        case "GET_ME":
            return {
                ...state,
                fetchingMe: true,
            }
        case "LOG_OUT":
            return initialState;
        case "SET_USER":
            return {
                ...state,
                fetchingMe: false,
                me: action.payload,
                isAuthenticated: true,
            }
        case "SET_ERROR":
            return {
                ...initialState,
                error: action.message,
                fetchingMe: false,
            }
        default:
            return state;
    }
}

export default AuthReducer;