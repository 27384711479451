import ApolloClient from 'apollo-boost'
import helpers      from "./helpers";

const client = new ApolloClient({
    uri: process.env.REACT_APP_BASE_URL_GRAPHQL,
    request: (operation) => {
        const token = localStorage.getItem(helpers.getTokenStr());

        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${ token }` : ''
            }
        })
    }
});

export default client;
