import { usePreviousType }   from "../types/helpers/dom";
import { useEffect, useRef } from "react";
import { AxiosResponse }     from "axios";
import { FormInstance }      from "antd/es/form";
import { DataField }         from "../components/Admin/EventForm/types/DataField";

const usePrevious: usePreviousType = <T>(value: T) => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

const setFormErrorsByAxiosResponse = <T extends object = any>(form: FormInstance, payload?: AxiosResponse<T>) => {
    if (payload !== undefined && payload.data !== undefined && payload.data.hasOwnProperty("errors")) {
        // @ts-ignore
        if (payload.data.errors !== undefined) {
            const fields: DataField[] = [];
            // @ts-ignore
            Object.entries(payload.data.errors).forEach(([key, value]) => {
                const errors = [value as string];
                const data = { name: key.split('.').map(k => isNaN(parseInt(k)) ? k : parseInt(k)), errors };

                fields.push(data as unknown as DataField);

            });

            form.setFields(fields);
        }
    }
}

export default { usePrevious, setFormErrorsByAxiosResponse };