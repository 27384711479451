import { FormInstance }              from "antd/lib/form";
import React                         from "react";
import IFormFields                   from "../types/IFormFields";
import { notification }              from "antd";
import { AxiosError, AxiosResponse } from "axios";
import IApiResponse                  from "../../../../interfaces/api/IApiResponse";
import EventsService                 from "../../../../api/services/EventsService";
import helpers                       from "../../../../helpers";

type handleFinishType = (
    form: FormInstance,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    isWebinar: boolean,
    onSuccess: () => any
) => void;

const handleFinish: handleFinishType = async (form, setLoading, isWebinar, onSuccess) => {
    const { getFieldsValue } = form;
    const values = getFieldsValue() as IFormFields;

    setLoading(true);

    const frmData = new FormData();
    const image = values.image !== null ? values.image[values.image.length - 1] : null;

    if (!isWebinar) {
        Object.keys(values).forEach(key => {
            if (['schedules', 'image', 'visible', 'attachments'].indexOf(key) === -1) {
                // @ts-ignore
                frmData.append(key, values[key]);
            }
        });
    } else {
        frmData.append("webinar_id", `${ values.webinar_id }`);
    }

    frmData.append("visible", `${ values.visible }`);

    if (image !== null) {
        // @ts-ignore
        frmData.append("thumbnail", image.originFileObj);
    }

    values.schedules.forEach(s => {
        frmData.append(`schedules[][full_date]`, s.date.format("yyyy-MM-DD") + " " + s.time.format("HH:mm:ss"));
    });

    values.attachments.forEach((a, _i) => {
        frmData.append(`attachments[${ _i }][name]`, a.name);
        frmData.append(`attachments[${ _i }][type]`, a.type);

        if (["IMAGE", "FILE"].indexOf(a.type) !== -1) {
            // @ts-ignore
            frmData.append(`attachments[${ _i }][file]`, a.file);
        } else {
            // @ts-ignore
            frmData.append(`attachments[${ _i }][url]`, a.url);
        }
    });

    const res = await EventsService.store(frmData);

    if (helpers.isAxiosError(res)) {
        const err = res as AxiosError;

        helpers.handleAxiosError(err, {
            422: () => helpers.setFormErrorsByAxiosResponse(form, err.response),
            default: () => notification.error({
                message: "Atención",
                description: "No se logró registrar el evento, inténtalo más tarde...",
                placement: "bottomLeft"
            })
        })
    } else {
        const { data } = (res as AxiosResponse<IApiResponse>);

        if (data !== undefined && data.status) {
            notification.success({
                message: "Proceso finalizado",
                description: "El evento ha sido creado éxitosamente",
                placement: "bottomLeft"
            });

            onSuccess();
        } else {
            notification.error({
                message: "Atención",
                description: "No se logró registrar el evento, inténtalo más tarde...",
                placement: "bottomLeft"
            });
        }
    }

    setLoading(false);
}

export default handleFinish;