import React, { Fragment, useEffect, useState } from "react";
import "../../styles/views/Public/Home.less";
import { Alert, Col, Layout, Row, Skeleton }    from "antd";
import section1Back                             from "../../assets/home/main-back.png";
import EventCard                                from "../../components/EventCard";
import chip                                     from "../../assets/home/chip.svg";
import cube                                     from "../../assets/home/cube.svg";
import smile                                    from "../../assets/home/smile.svg";
import film                                     from "../../assets/home/film.svg";
import section5Back                             from "../../assets/home/section-5-back.png";
import IEvent                                   from "../../interfaces/models/IEvent";
import { useQuery }                             from "react-apollo-hooks";
import gql                                      from "graphql-tag";
import GraphErrorAlert                          from "../../components/GraphErrorAlert";
import { Parallax }                             from 'rc-scroll-anim/lib';
import EventsContainer                          from "../../components/Home/EventsContainer";
import EventDetails                             from "../../components/Home/EventDetails";
import helpers                                  from "../../helpers";
import { useSelector }                          from "react-redux";
import IAppState                                from "../../interfaces/store/IAppState";

const perPage = {
    pending: 6,
    passed: 8,
}

const GET_CLOSEST_EVENT = gql`
    {
        event(closest: true) {
            id
            name
            description
            thumbnail
            stream_link
            webinar_id
            type_id
            schedules {
                date
            }
            user {
                name
                last_name
            }
        }
    }
`;

const GET_PENDING_EVENTS = gql`
    query GetPendingEvents($page: Int!, $perPage: Int!) {
        events(pending: true, page: $page, first: $perPage, orderBy: [{field: DATE, order: ASC}]) {
            data {
                id
                name
                description
                thumbnail
                stream_link
                webinar_id
                type_id
                schedules {
                    date
                }
                user {
                    name
                    last_name
                }
            }
            paginatorInfo {
                total
                hasMorePages
            }
        }
    }
`;

const GET_PASSED_EVENTS = gql`
    query GetPassedEvents($page: Int!, $perPage: Int!){
        events(passed: true, page: $page, first: $perPage, orderBy: [{field: DATE, order: DESC}]) {
            data {
                id
                name
                description
                thumbnail
                video_link
                schedules {
                    date
                }
                user {
                    name
                    last_name
                }
                attachments {
                    type {
                        code
                    }
                    name
                }
            }
            paginatorInfo {
                total
                hasMorePages
            }
        }
    }
`;

const icons: Array<{ icon: string, text: string, alt: string }> = [
    { icon: cube, text: "Gestión Acádemica", alt: "ESE Cube" },
    { icon: chip, text: "Metodología y Evaluación", alt: "ESE Chip" },
    { icon: film, text: "Herramientas Digitales", alt: "ESE Film" },
    { icon: smile, text: "Desarrollo Emocional", alt: "ESE Smile" },
];

const Home: React.FunctionComponent = () => {
    const anchorRef = useSelector<IAppState, HTMLAnchorElement | null>(({ ui }) => ui.anchorRef);
    const hidePendingEvents = useSelector<IAppState, boolean>(({ ui }) => ui.hidePendingEvents);

    const { loading: closestLoading, data: closestData, error: closestError } = useQuery<{ event: IEvent }>(GET_CLOSEST_EVENT, {
        fetchPolicy: "no-cache"
    });

    const [closestEvent, setClosestEvent] = useState<IEvent | null>(null);
    const defaultEvent = useSelector<IAppState, IEvent | null>(({ ui }) => ui.defaultEvent);

    useEffect(() => {
        if (!closestLoading && closestError === undefined && closestData?.event !== undefined) {
            setClosestEvent(closestData?.event);
        }
    }, [closestLoading, closestData, closestError]);

    return (
        <Layout id="home-component">
            { defaultEvent !== null && <EventCard
                event={ defaultEvent }
                mdlDetails={ EventDetails }
                closable={ false }
                onDetails={ () => helpers.onVideoDetail(defaultEvent, helpers.getEventTypeByDates(defaultEvent), anchorRef) }
                defaultOpen={ true }
                cardClassName="hide"
            /> }

            <Row>
                <Col id="section-1" span={ 24 }>
                    <h1>Viviendo nuevas experiencias de aprendizaje.</h1>
                    <img src={ section1Back } alt="Section 1 Back"/>
                </Col>

                <Col id="section-2" span={ 24 }>
                    <div className="info">
                        <h1>Hemos cambiado el lugar y el medio<br/>pero el aprendizaje debe continuar.</h1>

                        <p>
                            En Ediciones Servicios Educativos estamos comprometidos con asegurar la continuidad
                            educativa y queremos continuar apoyando a los docentes, directores y padres de familia para
                            que sigamos aprendiendo desde casa. Así como los estudiantes han pasado a aprender de manera
                            remota, queremos que ustedes sigan aprendiendo con esta plataforma que hemos desarrollado
                            con capacitaciones, charlas y talleres virtuales en las siguientes áreas
                        </p>

                        <div className="icons">
                            { icons.map((i, x) => (
                                <div className="_icon" key={ x }>
                                    <img src={ i.icon } alt={ i.alt }/>
                                    <p>{ i.text }</p>
                                </div>
                            )) }
                        </div>
                    </div>

                    { !(closestEvent === null && !closestLoading && closestError === undefined) && (
                        <Fragment>
                            <h1>Próximo Evento</h1>

                            <Parallax
                                always={ false }
                                animation={ { y: 0, playScale: [0, 1] } }
                                style={ { transform: 'translateY(200px)' } }
                            >
                                <div id="next-event">
                                    { closestLoading && (
                                        <div className="event-cont-skeleton">
                                            <div className="thumbnail"/>
                                            <div className="info">
                                                <Skeleton active loading/>
                                            </div>
                                        </div>
                                    ) }

                                    { closestEvent !== null && (
                                        <EventCard
                                            event={ closestEvent }
                                            size="lg"
                                            mdlDetails={ EventDetails }
                                            closable={ false }
                                        />
                                    ) }

                                    { closestEvent === null && !closestLoading && closestError === undefined && (
                                        <Alert type="error" showIcon message="No se encontró un evento próximo"/>
                                    ) }

                                    { closestError !== undefined && (
                                        <GraphErrorAlert error={ closestError }/>
                                    ) }
                                </div>
                            </Parallax>

                        </Fragment>
                    ) }

                </Col>

                { !hidePendingEvents && (
                    <Col id="section-3" span={ 24 }>
                        <EventsContainer title="Eventos" perPage={ perPage.pending } query={ GET_PENDING_EVENTS }/>
                    </Col>
                ) }

                <Col id="section-4" span={ 24 }>
                    <EventsContainer
                        title="Eventos pasados"
                        perPage={ perPage.passed }
                        query={ GET_PASSED_EVENTS }
                        size="sm"
                        type="VIDEO"
                    />
                </Col>

                <Col id="section-5" span={ 24 }>
                    <div className="info">
                        <Parallax
                            always={ false }
                            animation={ { x: 0, opacity: 1, playScale: [0.3, 0.7] } }
                            style={ { transform: 'translateX(-200px)', opacity: 0 } }
                        >
                            <h1> La educación es una responsabilidad compartida</h1>
                            <p>
                                Gracias por tu esfuerzo para asegurar que la calidad educativa continúe y por confiar en
                                nuestro equipo para apoyarte en este proceso.
                            </p>
                        </Parallax>
                    </div>
                    <div className="image">
                        <img src={ section5Back } alt="Section 5 back"/>
                    </div>
                </Col>
            </Row>
        </Layout>
    )
}

export default Home;