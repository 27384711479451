import { ThunkAction } from "redux-thunk";
import IAppState       from "../../interfaces/store/IAppState";
import { IUIActions }  from "../../interfaces/store/actions/IUIActions";
import * as types      from "./constants/ui";
import IEvent          from "../../interfaces/models/IEvent";

interface IUIAction<R, E = null> extends ThunkAction<R, IAppState, E, IUIActions> {
}

export const setLoader = (value: boolean, callback?: () => any): IUIAction<void> => (dispatch => {
    dispatch({
        type: types.SET_LOADER_STATUS,
        value
    });

    if (typeof callback === "function") {
        callback();
    }
});

export const SetMenuItems = (value: string[]): IUIAction<void> => (dispatch => dispatch({
    type: types.SET_SELECTED_MENU_ITEMS,
    value
}));

export const SetAnchorRef = (value: HTMLAnchorElement | null): IUIAction<void> => ((dispatch, getState) => {
    const ref = getState().ui.anchorRef;

    if (ref === null) {
        dispatch({
            type: types.SET_ANCHOR_REF,
            value
        });
    }
});

export const SetDefaultEvent = (value: IEvent, callback?: () => any): IUIAction<void> => (dispatch => {
    dispatch({
        type: types.SET_DEFAULT_EVENT,
        value
    });

    if (typeof callback === "function") {
        callback();
    }
});

export const SetHidePendingEvents = (value: boolean): IUIAction<void> => (dispatch => dispatch({
    type: types.SET_HIDE_PENDING_EVENTS,
    value
}));