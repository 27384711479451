import React, { useState }                                        from "react";
import { Alert, Button, DatePicker, Form, Input, Switch, Upload } from "antd";
import locale                                                     from "antd/es/date-picker/locale/es_ES";
import { useForm }                                                from "antd/es/form/util";
import { MinusCircleOutlined, PlusOutlined, UploadOutlined }      from "@ant-design/icons";
import handleFiles                                                from "./EventForm/functions/handleFiles";
import AttachmentActions                                          from "./EventForm/components/AttachmentActions";
import rules                                                      from "./EventForm/passedRules";
import handlePassedFinish                                         from "./EventForm/functions/handlePassedFinish";
import normFile                                                   from "./EventForm/functions/normFile";

const NewPassedEventForm: React.FunctionComponent = () => {
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [imgSrc, setImgSrc] = useState<string | null>(null);

    const [form] = useForm();

    return (
        <Form
            form={ form }
            onFinish={ () => handlePassedFinish(form, setLoading, () => {
                form.resetFields();
                setImgSrc(null);
            }) }
            layout="vertical"
            name="frmVideo"
            scrollToFirstError
            hideRequiredMark
            initialValues={ {
                name: "",
                description: "",
                link: "",
                visible: true,
                image: null,
                schedules: [],
                attachments: [],
            } }
        >
            <div className="title-cont">
                <h1>Crear Evento</h1>

                <div className="switch-wrap">
                    <span className={ isVisible ? 'active' : '' }>Visible</span>

                    <Form.Item name="visible" valuePropName="checked" noStyle>
                        <Switch onChange={ setIsVisible }/>
                    </Form.Item>
                </div>
            </div>

            <hr/>

            <Form.Item
                label="Nombre"
                name="name"
                rules={ rules.name }
            >
                <Input placeholder="¿Cual es el nombre del evento?"/>
            </Form.Item>

            <Form.List name="schedules">
                { (fields, { add, remove }) => (
                    <Form.Item
                        label={
                            <div>
                                <span>Horarios</span>
                                <Button icon={ <PlusOutlined/> } onClick={ () => add() }/>
                            </div>
                        }
                    >
                        { form.getFieldError("schedules").length > 0 && (
                            <Alert type="error" showIcon message={ form.getFieldError("schedules")[0] }/>
                        ) }

                        { fields.map((field, i) => (
                            <div key={ i } style={ { display: "flex" } }>
                                <Form.Item
                                    { ...field }
                                    name={ field.name }
                                    rules={ [{ required: true, message: "Debes ingresar una fecha" }] }
                                >
                                    <DatePicker
                                        locale={ locale }
                                        format="DD-MM-yyyy"
                                        placeholder="DD-MM-YYYY"
                                    />
                                </Form.Item>

                                <Button
                                    danger
                                    icon={ <MinusCircleOutlined/> }
                                    onClick={ () => remove(field.name) }
                                />
                            </div>
                        )) }
                    </Form.Item>
                ) }
            </Form.List>

            <Form.Item
                label="Descripción"
                name="description"
                rules={ rules.description }
            >
                <Input.TextArea placeholder="Escribe una descripción de que trata el evento"/>
            </Form.Item>

            <Form.Item label="Imagen">
                <Form.Item
                    name="image"
                    valuePropName="fileList"
                    getValueFromEvent={ normFile }
                    noStyle
                    rules={ rules.image }
                >
                    <Upload.Dragger
                        accept="image/*"
                        name="image"
                        beforeUpload={ (file, FileList) => handleFiles(form, file, FileList, src => setImgSrc(src)) }
                        showUploadList={ false }
                    >
                        { imgSrc !== null && <img className="custom-preview-img" src={ imgSrc } alt="Preview"/> }
                        { imgSrc === null && (
                            <div>
                                <p className="ant-upload-drag-icon">
                                    <UploadOutlined/>
                                </p>
                                <p className="ant-upload-text">Arrastra o seleccionar una imagen para el evento</p>
                            </div>
                        ) }
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item>

            <Form.Item
                label="Link"
                name="link"
                rules={ rules.link }
            >
                <Input
                    placeholder="Pega la URL del evento. Puede ser un link de Facebook u otra platafoma"
                />
            </Form.Item>

            <Form.Item
                label="Archivos de apoyo"
                noStyle
            >
                <Form.List
                    name="attachments"
                >
                    { (fields, operation) =>
                        <AttachmentActions form={ form } operation={ operation } fields={ fields } name="files"/>
                    }
                </Form.List>
            </Form.Item>

            <Form.Item className="form-item-submit-button-cont">
                <Button
                    icon={ <UploadOutlined/> }
                    loading={ loading }
                    type="primary"
                    htmlType="submit"
                    size="large"
                >
                    Publicar
                </Button>
            </Form.Item>
        </Form>
    );
}

export default NewPassedEventForm;