import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from "redux-thunk";
import auth from "./reducers/auth";
import ui from "./reducers/ui";

const rootStore = combineReducers({auth, ui});

let middleware;

if (process.env.NODE_ENV === 'production') {
    middleware = compose(applyMiddleware(thunk));
} else {
    middleware = compose(
        applyMiddleware(thunk),
        // @ts-ignore
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) || compose
    );
}

const store = createStore(rootStore, middleware);

export default store;