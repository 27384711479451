import React, {useEffect, useState} from "react";
import {Button, Form, Input, Layout, notification} from "antd";
import AuthService, {ICreateUserResponse} from "../../api/services/AuthService";
import helpers from "../../helpers";
import {AxiosError, AxiosResponse} from "axios";
import ISSOUser from "../../interfaces/models/ISSOUser";
import {useForm} from "antd/es/form/Form";
import {useHistory} from "react-router-dom";
import "../../styles/components/Home/CreateUserForm.less";

interface Props {
    token: string
}

interface IFields {
    first_name: string
    last_name: string
    email: string
}

const CreateUserForm: React.FunctionComponent<Props> = ({token}) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [form] = useForm();

    const getUser = async () => {
        setLoading(true);

        const res = await AuthService.getSSOUser(token);

        if (helpers.isAxiosError(res)) {
            const err = res as AxiosError;

            helpers.handleAxiosError(err, {
                default: () => {
                    notification.error({
                        placement: "bottomLeft",
                        message: "Atención",
                        description: "No se pudo obtener la información del usuario que se desea asociar, inténtalo más tarde"
                    });

                    history.push("/");
                },
            });
        } else {
            const {data} = res as AxiosResponse<ISSOUser>;
            form.setFieldsValue({...data});
        }

        setLoading(false);
    }

    const handleFinish = async (values: IFields) => {
        setLoading(true);
        const res = await AuthService.insertUser(values, token);

        if (helpers.isAxiosError(res)) {
            const err = res as AxiosError;

            helpers.handleAxiosError(err, {
                422: () => helpers.setFormErrorsByAxiosResponse(form, err.response),
                401: () => {
                    notification.error({
                        placement: "bottomLeft",
                        message: "Atención",
                        description: "No tienes permiso para efectuar la operación de crear un usuario..."
                    });

                    history.push("/");
                },
                default: () => {
                    notification.error({
                        placement: "bottomLeft",
                        message: "Atención",
                        description: "Atención al intentar registrar el usuario, inténtalo más tarde"
                    });

                    history.push("/");
                }
            });
        } else {
            const {data} = res as AxiosResponse<ICreateUserResponse>;

            if (data.status) {
                notification.success({
                    placement: "bottomLeft",
                    message: "Usuario creado éxitosamente",
                    description: "Ahora posees acceso a la plataforma"
                });

                history.push(`/login/${data.token}`);
            } else {
                notification.error({
                    placement: "bottomLeft",
                    message: "Atención",
                    description: "Atención al intentar registrar el usuario, inténtalo más tarde"
                });

                history.push('/');
            }
        }

        setLoading(false);
    }

    useEffect(() => {
        getUser().then();
        // eslint-disable-next-line
    }, [token]);

    return (
        <Layout id="create-user-form-component">

            <h1>Creación de usuario</h1>
            <p>
                El usuario con el que deseas ingresar al sistema no se encuentra registrado, confirma los siguientes
                datos
                personales para guardarlos en dicho sistema y así darte acceso al mismo.
            </p>

            <Form
                form={form}
                layout="vertical"
                hideRequiredMark
                initialValues={{
                    first_name: "",
                    last_name: "",
                    email: "",
                }}
                onFinish={values => handleFinish(values as IFields)}
            >
                <Form.Item
                    label="Nombre"
                    name="first_name"
                    rules={[{required: true, message: "Debes ingresar un nombre"}]}
                >
                    <Input disabled={loading}/>
                </Form.Item>

                <Form.Item
                    label="Apellido"
                    name="last_name"
                    rules={[{required: true, message: "Debes ingresar un apellido"}]}
                >
                    <Input disabled={loading}/>
                </Form.Item>

                <Form.Item
                    label="Correo electrónico"
                    name="email"
                    rules={[{required: true, message: "Debes ingresar un correo electrónico"}, {
                        type: "email",
                        message: "Debes ingresar un correo electrónico válido"
                    }]}
                >
                    <Input disabled={loading} type="email"/>
                </Form.Item>

                <Form.Item className="form-item-submit-button-cont">
                    <Button
                        disabled={loading}
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        size="large"
                    >
                        Guardar usuario
                    </Button>
                </Form.Item>
            </Form>
        </Layout>
    )
}

export default CreateUserForm;