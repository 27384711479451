import { AxiosInstance, AxiosRequestConfig } from "axios";
import getAxiosInstance                      from "./axios";

export default class Service<T> {
    protected entityName: string;
    protected http: AxiosInstance;

    constructor(entity: string, config: AxiosRequestConfig) {
        this.entityName = entity;
        this.http = getAxiosInstance(config);
    }
}