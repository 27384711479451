import {handleAxiosErrorType, handleGraphErrorType, isAxiosErrorType, ResponseKeys} from "../types/helpers/services";
import {AxiosError} from "axios";
import {ERRORS} from "../types/GraphQLErrorExtensions";

const isAxiosError: isAxiosErrorType = object => {
    if (object === undefined) return false;
    const err = object as AxiosError;

    const isServerError = err.isAxiosError !== undefined && err.isAxiosError;
    const isClientError = (err.code !== undefined && err.code !== "200") || (err.response?.status !== undefined && err.response?.status !== 200);

    return (isServerError || isClientError);
}

const handleGraphError: handleGraphErrorType = (error, callbacks) => {
    let i = 0;

    error.graphQLErrors.forEach(e => {
        if (e.extensions?.category !== undefined) {
            const type = e.extensions as ERRORS;

            if (callbacks[type.category] !== undefined && typeof callbacks[type.category] === "function") {
                i++;
                // @ts-ignore
                callbacks[type.category](e, type);
            }
        }
    });

    if (i === 0 && callbacks.default !== undefined) {
        callbacks.default(error);
    }
}

const handleAxiosError: handleAxiosErrorType = (error, callbacks) => {
    const allowedCodes = Object.keys(callbacks);

    const callDefault = () => {
        if (typeof callbacks.default === "function") {
            callbacks.default(error);
        }
    }

    if (error.code !== undefined && error.code === "500") {
        if (typeof callbacks["500"] === "function") {
            callbacks["500"](error);
        } else if (typeof callbacks.default === "function") {
            callDefault();
        }
    } else {
        const status: string | undefined = error.response?.status.toString();

        if (status !== undefined && allowedCodes.indexOf(status) !== -1) {
            try {
                const key = allowedCodes[allowedCodes.indexOf(status)] as unknown as ResponseKeys;

                if (callbacks[key] !== undefined && typeof callbacks[key] === "function") {
                    // @ts-ignore
                    callbacks[key](error.response);
                } else {
                    callDefault();
                }
            } catch (e) {
                callDefault();
            }
        } else {
            callDefault();
        }
    }
}

export default {handleGraphError, isAxiosError, handleAxiosError};