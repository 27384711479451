import {BrowserRouter} from "react-router-dom";
import React from "react";
import LayoutRoute from "./LayoutRoute";
import PublicLayout from "../views/Layouts/Public";
import AdminLayout from "../views/Layouts/Admin";
import PublicViews from "../views/Public";
import PrivateViews from "../views/Private";

const Router: React.FunctionComponent = () => {
    return (
        <BrowserRouter>
            <LayoutRoute middleware="AUTH" Layout={AdminLayout} exact path="/admin/" component={PrivateViews.Home}/>

            <LayoutRoute
                middleware="GUEST"
                Layout={PublicLayout} exact path="/login/:token?"
                component={PublicViews.Login}
            />

            <LayoutRoute Layout={PublicLayout} exact path="/event/:id?" component={PublicViews.SetEvent}/>
            <LayoutRoute Layout={PublicLayout} exact path="/video/:event_id" component={PublicViews.Video}/>
            <LayoutRoute Layout={PublicLayout} exact path="/" component={PublicViews.Home}/>
        </BrowserRouter>
    )
}

export default Router;