import React, {useState} from "react";
import IEvent from "../../interfaces/models/IEvent";
import {Button, Modal, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilm, faTimes, faVideo} from "@fortawesome/free-solid-svg-icons";
import RegisterAttendanceForm from "./RegisterAttendanceForm";
import helpers from "../../helpers";
import {CardDetailsProps} from "../../types/entities/Event";
import {useSelector} from "react-redux";
import IAppState from "../../interfaces/store/IAppState";

const EventDetails: React.FunctionComponent<CardDetailsProps> = ({event, closestDate}) => {
    const [showAttendanceMdl, setShowAttendanceMdl] = useState(false);
    const anchorRef = useSelector<IAppState, HTMLAnchorElement | null>(({ui}) => ui.anchorRef);

    const isWebinarType: (e: IEvent) => boolean = (e) => {
        return e.webinar_id !== undefined && e.webinar_id !== null;
    }

    const onAttendanceMdlClose = () => setShowAttendanceMdl(false);

    const handleInterestClick: () => void = () => {
        if (anchorRef !== null) {
            if (isWebinarType(event)) {
                setShowAttendanceMdl(true);
            } else {
                if (event.video_link !== undefined && event.video_link !== null) {
                    if (helpers.isBucketUrl(event.video_link) || helpers.getYoutubeIdFromUrl(event.video_link) !== event.video_link) {
                        anchorRef.href = `${window.location.href}video/${event.id}`;
                    } else {
                        anchorRef.href = event.video_link;
                    }
                } else if (event.stream_link !== undefined) {
                    anchorRef.href = event.stream_link;
                }

                anchorRef.click();
            }
        }
    }

    const isStream = helpers.getEventTypeByDates(event) === "STREAM";

    return (
        <div>
            <div className="thumbnail" style={{backgroundImage: `url(${event.thumbnail})`}}>
                <Tooltip title={isStream ? "Directo" : "Video"}>
                    <div className="icon">
                        <FontAwesomeIcon icon={isStream ? faVideo : faFilm}/>
                    </div>
                </Tooltip>
            </div>

            <div className="info">
                <div className="info-wrapper">
                    <p className="name">{event.name}</p>
                    {/*<p className="time">{closestDate}</p>*/}
                    <p className="description">{event.description}</p>

                    <h2>Organizado por</h2>
                    <p className="organizer">{event.user?.name} {event.user?.last_name}</p>
                </div>
                <Button type="default" size="large" onClick={handleInterestClick}>
                    Me interesa
                </Button>
            </div>

            <Modal
                className="add-attendance-form"
                visible={showAttendanceMdl}
                onCancel={onAttendanceMdlClose}
                closable={false}
                footer={null}
                width={600}
            >
                <Button onClick={onAttendanceMdlClose}>
                    <FontAwesomeIcon icon={faTimes}/>
                </Button>

                <RegisterAttendanceForm event_id={event.id} closestDate={closestDate} streamLink={event.stream_link}/>
            </Modal>
        </div>
    )
}

export default EventDetails;