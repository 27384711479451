import React from "react";
import Phone from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import es from 'react-phone-input-2/lang/es.json'

interface Props {
    id?: string
    value?: string
    onChange?: (value: string) => any
}

const PhoneInput: React.FunctionComponent<Props> = ({value, id, onChange}) => {

    return (
        <Phone
            containerClass="phone-input-container"
            localization={es}
            country="sv"
            value={value}
            onChange={(phone, data, event, formattedValue) => {
                if (onChange !== undefined) {
                    onChange(formattedValue);
                }
            }}
            inputProps={{
                name: id
            }}
        />
    )
}

export default PhoneInput;