import React from "react";
import "../styles/components/Loader.less";
import {Spin} from "antd";

interface Props {
    isLoading: boolean,
}

const Loader: React.FC<Props> = ({isLoading, children}) => (
    <div>
        <div id="loader-back" className={isLoading ? "show" : ""}>
            <Spin spinning size="large"/>
            <p>Cargando...</p>
        </div>
        {children}
    </div>
)

export default Loader;