import React, {useCallback, useEffect, useState} from "react";
import "../../styles/layouts/General.less";
import {Button, Col, Drawer, Layout, Row} from "antd";
import logoESE from "../../assets/ese-logo.png";
import logo from "../../assets/logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp, faBars} from "@fortawesome/free-solid-svg-icons";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import getMenuItems from "../../components/Home/MenuItems";
import Menu from "../../components/Menu";
import IAppState from "../../interfaces/store/IAppState";
import {SetMenuItems} from "../../store/actions/ui";

const {Header, Content, Footer} = Layout;

const PublicLayout: React.FunctionComponent = ({children}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const selectedItems = useSelector<IAppState, string[]>(({ui}) => ui.selectedMenuItems);

    const [drawer, setDrawer] = useState(false);

    const menuItems = getMenuItems(dispatch);

    const onChangePage = useCallback(() => {
        const e: HTMLElement | null = document.getElementById("public-app-layout");
        e?.scrollIntoView({block: "start", behavior: "smooth"});
        setDrawer(prevState => prevState ? false : prevState);
        dispatch(SetMenuItems([location.pathname]));
    }, [dispatch, location.pathname]);

    useEffect(() => {
        onChangePage();
    }, [location.pathname, onChangePage])

    useEffect(() => {
        setDrawer(prevState => prevState ? false : prevState);
    }, [selectedItems])

    const OnDrawerClose = () => setDrawer(false);

    return (
        <Layout className="general-app-layout" id="public-app-layout">
            <Drawer
                visible={drawer}
                closable={false}
                placement="top"
                onClose={OnDrawerClose}
                width="100vw"
                height="100vh"
                title={(
                    <div className="sidenav-logo-title">
                        <img src={logo} alt="ESE Logo"/>
                        <Button onClick={OnDrawerClose} type="link"><FontAwesomeIcon icon={faAngleUp}/></Button>
                    </div>
                )}
            >
                <Menu items={menuItems} mode="vertical"/>
            </Drawer>
            <Header className="general-app-header" id="public-header">
                <div className="header-logo">
                    <div className="logo-cont">
                        <img src={logo} alt="ESE Logo"/>
                    </div>
                    <Button
                        className="show-sm"
                        type="link"
                        id="btnShowSidenav"
                        onClick={() => setDrawer(true)}
                    >
                        <FontAwesomeIcon size="1x" icon={faBars}/>
                    </Button>
                </div>

                <Menu items={menuItems} mode="horizontal" className="hide-sm"/>
            </Header>

            <Content id="main-app-container">
                {children}
            </Content>

            <Footer id="public-footer">
                <Row>
                    <Col md={8} sm={24} className="img-cont">
                        <img src={logoESE} alt="ESE Logo"/>
                        <p>Copyright © 2020 Ediciones Servicios Educativos</p>
                        <Col>
                            <Row>
                                6a 10a Calle Poniente #1713, frente al Tin Marín. <br/>Ediciones Servicios Educativos
                            </Row>
                            <Row>
                                PBX: +503 2506-4300 <br/>Atención al cliente: +503 2506-4337
                            </Row>
                        </Col>
                    </Col>
                    <Col md={8} sm={24} className="info-cont">
                        <h2>Nuestros productos</h2>
                        <ul>
                            <li>e-School</li>
                            <li>e-School Onlineclass</li>
                            <li>ese Virtual</li>
                            <li>ese Ediciones</li>
                        </ul>
                    </Col>
                </Row>
            </Footer>
        </Layout>
    );
}

export default PublicLayout;