import { Rule }          from "antd/es/form";
import { RcFile }        from "antd/lib/upload/interface";
import helpers           from "../../../helpers";
import IPassedFormFields from "./types/IPassedFormFields";

const rules: { [K in keyof IPassedFormFields]?: Rule[] } = {
    name: [
        { required: true, message: "Debes ingresar un nombre del evento" },
    ],
    image: [
        {
            validator: (_, value: RcFile[]) => {
                if (value !== null) {
                    const file = value[value.length - 1];
                    if (file === undefined || file === null) return Promise.reject("Debes seleccionar una imagen para el evento");

                    if (helpers.isImage(file)) {
                        return Promise.resolve();
                    } else {
                        return Promise.reject("Debes seleccionar una imagen")
                    }
                } else {
                    return Promise.reject("Debes seleccionar una imagen para el evento");
                }
            }
        }
    ],
    link: [
        { required: true, message: "Debes ingresar una enlace para el evento" },
    ],
    description: [
        { required: true, message: "Debes ingresar una descripción para el evento" },
    ]
}

export default rules;