import * as React from "react";
import {useEffect, useState} from "react";
import gql from "graphql-tag";
import {useMutation} from "react-apollo-hooks";
import {Alert, Button, Form, Input} from "antd";
import {Store} from "antd/lib/form/interface";
import {Rule} from "rc-field-form/lib/interface";
import "../../styles/components/Home/RegisterAttendanceModal.less";
import sentGif from "../../assets/sent.gif";
import RcQueueAnim from "rc-queue-anim";
import helpers from "../../helpers";
import PhoneInput from "../Form/PhoneInput";
import moment, {Moment} from "moment";
import {useSelector} from "react-redux";
import IAppState from "../../interfaces/store/IAppState";

interface Props {
    event_id: string
    closestDate?: Moment | null
    streamLink: string
}

interface IField {
    name: string
    label: string
    rules: Array<Rule>
    placeholder: string
    type?: string
}

const ADD_ATTENDANCE = gql`
    mutation AddAttendance($first_name: String!, $last_name: String!, $email: String!, $institution: String!, $phone: String, $event_id: String!) {
        insertAttendance(input: {
            first_name: $first_name
            last_name: $last_name
            email: $email
            institution: $institution
            phone: $phone
            event_id: $event_id
        })
    }
`;

const fields: Array<IField> = [
    {
        label: "Nombre",
        name: "name",
        placeholder: "¿Cuál es tu nombre?",
        type: "text",
        rules: [{required: true, message: "Debes ingresar un nombre"}]
    }, {
        label: "Apellido",
        name: "last_name",
        placeholder: "¿Cuál es tu apellido?",
        type: "text",
        rules: [{required: true, message: "Debes ingresar un apellido"}]
    }, {
        label: "Correo electrónico",
        name: "email",
        placeholder: "¿Cuál es tu correo electrónico?",
        type: "email",
        rules: [
            {required: true, message: "Debes ingresar un correo"}, {
                type: "email",
                message: "Debes ingresar un correo electrónico válido"
            }
        ]
    }, {
        label: "Institución",
        name: "institution",
        placeholder: "¿A qué institución perteneces?",
        type: "test",
        rules: [{required: true, message: "Debes ingresar una institución"}]
    }
];

const RegisterAttendanceForm: React.FunctionComponent<Props> = ({event_id, closestDate, streamLink}) => {
    const [form] = Form.useForm();
    const [status, setStatus] = useState(false);
    const [displayableError, setDisplayableError] = useState<string | null>(null);
    const [addAttendance, {loading, data, error}] = useMutation<{ insertAttendance: boolean }>(ADD_ATTENDANCE, {
        errorPolicy: "all"
    });

    const [isInTimeThreshold, setIsInTimeThreshold] = useState(false);
    const anchorRef = useSelector<IAppState, HTMLAnchorElement | null>(({ui}) => ui.anchorRef);

    const handleSubmit = (values: Store) => {
        addAttendance({
            variables: {
                first_name: values.name,
                last_name: values.last_name,
                email: values.email,
                institution: values.institution,
                phone: values.phone,
                event_id
            }
        }).then();
    }

    const checkTimeThreshold: (date: Moment) => boolean = (date) => {
        const now = moment();
        const diff = date.diff(now, "m");
        return diff <= 30;
    }

    useEffect(() => {
        if (closestDate !== undefined && closestDate !== null) {
            setIsInTimeThreshold(checkTimeThreshold(closestDate));
        }
    }, [closestDate])

    useEffect(() => {
        if (data !== undefined) {
            if (data?.insertAttendance === true) {
                setStatus(true);

                if (isInTimeThreshold && anchorRef !== null) {
                    anchorRef.href = streamLink;
                    anchorRef.click();
                }
            } else {
                setDisplayableError("El registro de tu asistencia al evento no pudo llevarse a cabo, inténtalo más tarde");
            }
        }
    }, [anchorRef, data, isInTimeThreshold, streamLink]);

    useEffect(() => {
        if (error !== undefined) {
            form.resetFields();
            helpers.handleGraphError(error, {
                validation: (e, extensions) => {
                    if (extensions.already_exists === true) {
                        setDisplayableError("La asistencia a ese evento bajo ese correo electrónico ya existe!");
                    }
                },
                unprocessable_entity: () => {
                    setDisplayableError("No se pudo completar tu registro porque el evento ya ha empezado...");
                },
                default: () => {
                    setDisplayableError("Ha ocurrido un error y no se pudo registrar tu asistencia al evento, inténtalo más tarde...");
                }
            })
        }
    }, [error, form]);

    return (
        <div>
            {!status && (
                <div>
                    <h1>Enviar solicitud</h1>
                    <p>Enviaremos el link del evento a tu correo</p>

                    {displayableError !== null && (
                        <RcQueueAnim
                            type="bottom"
                            duration={500}
                        >
                            <Alert
                                key="alert"
                                showIcon
                                closable
                                type="error"
                                message="Atención"
                                description={displayableError}
                            />
                        </RcQueueAnim>
                    )}

                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        layout="vertical"
                        initialValues={{name: "", last_name: "", email: "", phone: "", institution: ""}}
                    >
                        {fields.map((f, i) => (
                            <Form.Item
                                key={i}
                                label={f.label}
                                name={f.name}
                                rules={f.rules}
                            >
                                <Input disabled={loading} type={f.type} placeholder={f.placeholder}/>
                            </Form.Item>
                        ))}
                        <Form.Item
                            label="Número de teléfono" name="phone"
                            rules={[{required: true, message: "Debes ingresar un número de teléfono"}]}
                        >
                            <PhoneInput/>
                        </Form.Item>
                        <Form.Item className="form-item-submit-button-cont">
                            <Button loading={loading} type="primary" htmlType="submit" size="large">Enviar</Button>
                        </Form.Item>
                    </Form>
                </div>
            )}

            {status && (isInTimeThreshold ? (
                <RcQueueAnim
                    duration={1000}
                    type="bottom"
                >
                    <h1 key="title">El evento tomará lugar pronto o ya ha empezado!</h1>
                    <p key="subtitle">
                        Te facilitamos el acceso y automáticamente te abrimos el enlace en otra pestaña.
                        Sí no se logró abrir te adjuntamos el enlace
                    </p>
                    <a
                        style={{textAlign: "center", width: "100%", display: "block"}}
                        href={streamLink}
                        rel="noopener noreferrer" target="_blank"
                    >
                        {streamLink}
                    </a>
                </RcQueueAnim>
            ) : (
                <RcQueueAnim
                    duration={1000}
                    type="bottom"
                >
                    <h1 key="title">Solicitud enviada</h1>
                    <p key="subtitle">En unos momentos recibiras el link del evento a tu correo</p>
                    <img key="image" src={sentGif} alt="sent gif"/>
                </RcQueueAnim>
            ))}
        </div>
    )
}

export default RegisterAttendanceForm;