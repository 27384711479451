import Service from "./Service";
import {AxiosError, AxiosResponse} from "axios";
import IApiResponse from "../interfaces/api/IApiResponse";
import IPaginatedApiData from "../interfaces/helpers/IPaginatedApiData";

export default class Class<E> extends Service<E> {
    public async fetch(page?: number): Promise<AxiosResponse<E[] | IPaginatedApiData<E>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${page}` : "";
            return await this.http.get<E[]>(`${this.entityName}${params}`);
        } catch (ex) {
            return ex as AxiosError;
        }
    }

    public async get(id: string): Promise<AxiosResponse<E> | AxiosError> {
        try {
            return await this.http.get<E>(`${this.entityName}/${id}`);
        } catch (ex) {
            return ex as AxiosError;
        }
    }

    public async store<T = object>(entity: T): Promise<AxiosResponse<IApiResponse<T>> | AxiosError> {
        try {
            return await this.http.post(`${this.entityName}`, entity, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (ex) {
            return ex as AxiosError;
        }
    }

    public async update<T = object>(id: string, entity: T): Promise<AxiosResponse<IApiResponse<T>> | AxiosError> {
        try {
            return await this.http.post<IApiResponse<T>>(`${this.entityName}/${id}`, entity);
        } catch (ex) {
            return ex as AxiosError;
        }
    }

    public async delete(id: string): Promise<IApiResponse<E> | AxiosError> {
        try {
            /**
             * TODO: Tipificar función delete
             * */
            const res: AxiosResponse<IApiResponse<E>> = await this.http.delete(`${this.entityName}/${id}`);
            return res.data;
        } catch (ex) {
            return ex as AxiosError;
        }
    }
}