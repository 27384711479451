import {ApolloError} from "apollo-boost";
import React from "react";
import {Alert, List} from "antd";
import QueueAnim from "rc-queue-anim";
import {GraphQLError} from "graphql";
import {ERRORS} from "../types/GraphQLErrorExtensions";


interface Props {
    error: ApolloError
}

const GraphErrorAlert: React.FunctionComponent<Props> = ({error}) => {

    const isDev = process.env.NODE_ENV === "development";

    const getType: (e: GraphQLError) => string = (e) => {
        if (e.extensions?.category !== undefined) {
            const type = e.extensions as ERRORS;

            if (type.category === "unprocessable_entity") {
                return "Entidad improcesable";
            } else if (type.category === "validation") {
                return "Validación";
            } else {
                return "Error";
            }
        } else {
            return "Error";
        }
    }

    const Description: React.FunctionComponent = () => {
        if (!isDev) {
            return <span>Inténtalo más tarde...</span>;
        } else {
            return <List size="small" style={{paddingLeft: 0}}>
                {error.graphQLErrors.map((e, i) => <List.Item key={i}><b>[{getType(e)}]</b> {e.message}</List.Item>)}
            </List>
        }
    };

    return (
        <QueueAnim
            key="aux-error-queue-anim-key"
            type="bottom"
            duration={500}
        >
            <Alert
                key="__error"
                type="error"
                showIcon
                message="Atención, ha ocurrido un inconvenente"
                description={<Description/>}
            />
        </QueueAnim>
    );
}
export default GraphErrorAlert;