import React, { FC, Fragment, useState }                                      from 'react';
import { StoreValue }                                                         from "rc-field-form/lib/interface";
import ButtonGroup                                                            from "antd/lib/button/button-group";
import { Button, Form, message, Upload }                                      from "antd";
import { actionHandleFileType, AttachmentActionsType }                        from "../types/attachments";
import { FileImageOutlined, FileOutlined, LinkOutlined, VideoCameraOutlined } from "@ant-design/icons";
import helpers                                                                from "../../../../helpers";
import normFile                                                               from "../functions/normFile";
import { imgRegex }                                                           from "../../../../helpers/utils";
import { UploadChangeParam }                                                  from "antd/es/upload";
import { FormInstance }                                                       from "antd/lib/form";
import { DataField }                                                          from "../types/DataField";
import FileTypeInput                                                          from "./FileTypeInput";

export type addType = (val: StoreValue) => void

interface AActionsProps {
    name: string
    fields: DataField[]
    operation: {
        add: (defaultValue?: StoreValue) => void;
        remove: (index: number) => void;
        move: (from: number, to: number) => void;
    }
    form: FormInstance
}


const actions: AttachmentActionsType[] = [
    {
        icon: <FileOutlined/>,
        name: "Subir archivos",
        type: "FILE",
        criteria: file => {
            if (helpers.isImage(file)) {
                message.error("No debes ingresar una imagen");
                return false
            }

            return true;
        },
        accept: "",
        needsFile: true
    },
    {
        icon: <FileImageOutlined/>,
        name: "Subir imagen",
        type: "IMAGE",
        accept: "image/*",
        criteria: file => {
            if (!helpers.isImage(file)) {
                message.error("Debes ingresar una imagen");
                return false
            }

            return true;
        },
        needsFile: true
    },
    {
        icon: <VideoCameraOutlined/>,
        name: "Agregar link de vídeo",
        type: "VIDEO",
        needsFile: false
    },
    {
        icon: <LinkOutlined/>,
        name: "Agregar link",
        type: "LINK",
        needsFile: false
    }
];

const AttachmentActions: React.FC<AActionsProps> = (props) => {
    const { fields, operation, form } = props;
    const [filesCount, setFilesCount] = useState(fields.length);

    const addFile: actionHandleFileType = (file, type) => {
        const name = file.name.split(imgRegex)[0];

        operation.add({
            type,
            file,
            name
        });

        setFilesCount(i => i + 1);

        return false;
    }

    const removeFile = (index: number) => {
        setFilesCount(i => i - 1);
        operation.remove(index);
    }

    const onChange = (info: UploadChangeParam, action: AttachmentActionsType) => {
        if (filesCount <= 5) {
            const { file, fileList: files } = info;
            const criteria = action.criteria ? action.criteria(file, files) : false;

            if (!criteria) {
                return false;
            }

            return addFile(file, action.type)
        } else {
            message.error('No puedes añadir más adjuntos');
            return;
        }
    }

    const Btn: FC<{ action: AttachmentActionsType }> = ({ action }) => (
        <Button
            size={ "large" }
            disabled={ filesCount === 5 }
            icon={ action.icon }
            type="primary"
            onClick={ () => {
                if (!action.needsFile) {
                    setFilesCount(i => i + 1);
                    operation.add({
                        type: action.type,
                        url: ''
                    });
                }
            } }
        >
            { action.name }
        </Button>
    );

    return (
        <Fragment>
            <ButtonGroup size="large">
                { actions.map((a, i) => (
                    <Form.Item
                        key={ `attachment_action_${ i }` }
                        valuePropName="fileList"
                        getValueFromEvent={ normFile }
                        noStyle
                    >
                        { a.needsFile && (
                            <Upload
                                accept={ a.accept }
                                // multiple
                                showUploadList={ false }
                                beforeUpload={ () => false }
                                onChange={ info => onChange(info, a) }
                            >
                                <Btn action={ a }/>
                            </Upload>
                        ) }

                        { !a.needsFile && <span>
                            <Btn action={ a }/>
                        </span> }
                    </Form.Item>
                )) }
            </ButtonGroup>

            { fields.map((field, i) =>
                <FileTypeInput key={ i } _index={ i } form={ form } field={ field } removeFile={ removeFile }/>
            ) }
        </Fragment>
    );
}

export default AttachmentActions;