import { FormInstance }              from "antd/lib/form";
import React                         from "react";
import { notification }              from "antd";
import { AxiosError, AxiosResponse } from "axios";
import IApiResponse                  from "../../../../interfaces/api/IApiResponse";
import EventsService                 from "../../../../api/services/EventsService";
import helpers                       from "../../../../helpers";
import IPassedFormFields             from "../types/IPassedFormFields";

type handleFinishType = (
    form: FormInstance,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    onSuccess: () => any
) => void;

const handlePassedFinish: handleFinishType = async (form, setLoading, onSuccess) => {
    const { getFieldsValue } = form;
    const values = getFieldsValue() as IPassedFormFields;

    setLoading(true);
    const frmData = new FormData();
    const image = values.image !== null ? values.image[values.image.length - 1] : null;

    Object.keys(values).forEach((key) => {
        if (['schedules', 'image', 'visible', 'attachments'].indexOf(key) === -1) {
            // @ts-ignore
            frmData.append(key, values[key]);
        }
    });

    frmData.append("visible", `${ values.visible }`);

    if (image !== null) {
        // @ts-ignore
        frmData.append("thumbnail", image.originFileObj);
    }

    values.schedules.forEach(s => frmData.append(`schedules[]`, s.format("yyyy-MM-DD")));

    values.attachments.forEach((a, _i) => {
        frmData.append(`attachments[${ _i }][name]`, a.name);
        frmData.append(`attachments[${ _i }][type]`, a.type);

        if (["IMAGE", "FILE"].indexOf(a.type) !== -1) {
            // @ts-ignore
            frmData.append(`attachments[${ _i }][file]`, a.file);
        } else {
            // @ts-ignore
            frmData.append(`attachments[${ _i }][url]`, a.url);
        }
    });


    const res = await EventsService.storePassedEvent(frmData);

    if (helpers.isAxiosError(res)) {
        const err = res as AxiosError;
        helpers.handleAxiosError(err, {
            422: () => helpers.setFormErrorsByAxiosResponse(form, err.response),
            default: () => notification.error({
                message: "Atención",
                description: "No se logró registrar el evento, inténtalo más tarde...",
                placement: "bottomLeft"
            })
        });
    } else {
        const { data } = res as AxiosResponse<IApiResponse>;

        if (data !== undefined && data.status) {
            form.resetFields();
            onSuccess();

            notification.success({
                message: "Proceso finalizado",
                description: "El evento ha sido creado éxitosamente",
                placement: "bottomLeft"
            });
        } else {
            notification.error({
                message: "Atención",
                description: "No se logró registrar el evento, inténtalo más tarde...",
                placement: "bottomLeft"
            });
        }
    }


    setLoading(false);

}

export default handlePassedFinish;