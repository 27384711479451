import React, { useEffect, useState }                                                          from "react";
import { Alert, Button, DatePicker, Form, Input, message, Select, Switch, TimePicker, Upload } from "antd";
import moment, { Moment }                                                                      from "moment";
import helpers                                                                                 from "../../helpers";
import locale
                                                                                               from "antd/es/date-picker/locale/es_ES";
import { CopyOutlined, DownloadOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined }   from "@ant-design/icons";
import { useForm }                                                                             from "antd/es/form/util";
import { CardDetailsProps }                                                                    from "../../types/entities/Event";
import IWebinar
                                                                                               from "../../interfaces/models/IWebinar";
import EventsService
                                                                                               from "../../api/services/EventsService";
import { AxiosError, AxiosResponse }                                                           from "axios";
import QueueAnim                                                                               from "rc-queue-anim";
import ISchedule
                                                                                               from "../../interfaces/models/ISchedule";
import copy                                                                                    from 'copy-to-clipboard';
import normFile
                                                                                               from "./EventForm/functions/normFile";
import handleFiles
                                                                                               from "./EventForm/functions/handleFiles";
import AttachmentActions
                                                                                               from "./EventForm/components/AttachmentActions";
import handleFinishEdit
                                                                                               from "./EventForm/functions/handleFinishEdit";
import rules
                                                                                               from "./EventForm/editRules";
import {
    ADontNeedFile,
    ANeedsFile
}                                                                                              from "./EventForm/types/IFormFields";

type EventScheduleInfoType = () => { date: Moment | null, time: Moment | null };

const EditEventForm: React.FunctionComponent<CardDetailsProps> = ({ event, closeMdl, onSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(event.public);
    const [imgSrc, setImgSrc] = useState<string | null>(event.thumbnail);

    const [webinars, setWebinars] = useState<IWebinar[] | null>(null);
    const [isWebinar, setIsWebinar] = useState(false);
    const [loadingWebinars, setLoadingWebinars] = useState(false);
    const [getWebinarsError, setGetWebinarsError] = useState<string | null>(null);

    const [form] = useForm();

    const ScheduleInfo: EventScheduleInfoType = () => {
        if (event.schedules !== undefined && event.schedules.length > 0) {
            const sorted = event.schedules.filter(s => s.date !== null).sort((a, b) => (moment(a.date).diff(moment(b.date))));
            const closest = sorted[0];

            if (closest !== undefined) {
                return {
                    time: moment(closest.date, helpers.backDateFormat),
                    date: moment(closest.date, helpers.backDateFormat)
                }
            }
        }

        return { date: null, time: null };
    }

    const s = ScheduleInfo();

    const getWebinars = async () => {
        setLoadingWebinars(true);
        const res = await EventsService.getWebinars(true);

        if (helpers.isAxiosError(res)) {
            const err = res as AxiosError;

            helpers.handleAxiosError(err, {
                default: () => setGetWebinarsError("No se pudieron obtener las Webinars, inténtalo más tarde")
            })
        } else {
            const { data: webinars } = (res as AxiosResponse<IWebinar[]>);
            setWebinars(webinars);
        }
        setLoadingWebinars(false);
    }

    const toggleAssociateWebinar = () => {
        setIsWebinar(prevState => !prevState);
    }

    const fillValuesWithWebinar = (webinar_id: number) => {
        if (webinars !== null) {
            const webinar = webinars.find(({ webinar_id: id }) => id === webinar_id);

            if (webinar !== undefined) {
                form.setFieldsValue({
                    name: webinar.name,
                    description: webinar.description,
                    stream_link: webinar.direct_live_room_url,
                    video_link: webinar.direct_replay_room_url,
                    schedules: webinar.schedules.map(s => ({
                        date: moment(s.date, "YYYY-MM-DD HH:mm"),
                        time: moment(s.date, "YYYY-MM-DD HH:mm"),
                    }))
                });
            }
        }
    }

    useEffect(() => {
        if (webinars === null) {
            getWebinars().then();
        }
    }, [webinars])

    useEffect(() => {
        form.resetFields();
        setImgSrc(event.thumbnail);
        // eslint-disable-next-line
    }, [form, isWebinar])

    const scheduleHasAttendances: (schedules: ISchedule[] | undefined, index: number) => boolean = (schedules, index) => {
        if (schedules !== undefined && schedules.map((s, i) => i).indexOf(index) !== -1) {
            const s = schedules[index];
            return s !== undefined && s.attendances !== undefined && s.attendances.length > 0;
        } else {
            return false;
        }
    }

    const copyUrl = () => {
        copy(`${ window.location.origin }/event/${ event.id }`);
        message.success('La url ha sido copiada!');
    }

    return (
        <Form
            form={ form }
            name="frmEditEvent"
            layout="vertical"
            initialValues={ {
                name: event.name,
                description: event.description,
                date: s.date,
                time: s.time,
                stream_link: event.stream_link,
                video_link: event.video_link,
                visible: event.public,
                image: null,
                schedules: event.schedules?.map(s => ({
                    id: s.id,
                    date: moment(s.date, helpers.backDateFormat),
                    time: moment(s.date, helpers.backDateFormat),
                    full_date: null
                })),
                attachments: event.attachments?.map(a => {
                    if (["FILE", "IMAGE"].indexOf(a.type?.code ?? "") !== -1) {
                        return {
                            name: a.name,
                            type: a.type?.code,
                            disabled: true
                        } as ANeedsFile;
                    } else {
                        return {
                            disabled: true,
                            name: a.name,
                            type: a.type?.code,
                            url: a.url
                        } as ADontNeedFile;
                    }
                }) ?? [],
            } }
            scrollToFirstError
            hideRequiredMark
            onFinish={ () => handleFinishEdit(form, setLoading, isWebinar, event, () => {
                if (closeMdl !== undefined) {
                    closeMdl();
                }

                if (onSuccess !== undefined) {
                    onSuccess();
                }
            }) }
        >
            <div className="title-cont">
                <h1>Editar Evento</h1>

                <div className="switch-wrap">
                    <span className={ isVisible ? 'active' : '' }>Visible</span>

                    <Form.Item name="visible" valuePropName="checked" noStyle>
                        <Switch onChange={ setIsVisible }/>
                    </Form.Item>
                </div>
            </div>

            <div className="form-actions-cont">
                <Button onClick={ copyUrl } type="default" icon={ <CopyOutlined/> }>Copiar enlace a evento</Button>

                <Button
                    icon={ <DownloadOutlined/> }
                    href={ `${ process.env.REACT_APP_BASE_URL }/attendances/export/${ event.id }` }
                    download
                    type="default"
                >
                    Descargar asistencias al evento (CSV)
                </Button>
            </div>

            <div className="sync-container">
                <h1>¿Estas usando WerbinarJam? Sincroniza tu evento</h1>
                <Button
                    size="large"
                    loading={ loadingWebinars }
                    disabled={ getWebinarsError !== null || event.webinar_id !== null }
                    onClick={ toggleAssociateWebinar }
                    danger={ isWebinar }
                >
                    { isWebinar ? 'Cancelar' : event.webinar_id === null ? 'Sincronizar' : 'El evento ya se encuentra sincronizado' }
                </Button>
            </div>

            { getWebinarsError !== null && (
                <QueueAnim type="bottom">
                    <Alert type="error" showIcon message={ getWebinarsError }/>
                </QueueAnim>
            ) }

            { isWebinar && webinars !== null && (
                <Form.Item
                    label="Webinar a asignar"
                    name="webinar_id"
                    rules={ isWebinar ? [{
                        required: true,
                        message: "Debes seleccionar una Webinar para asignar"
                    }] : [] }
                >
                    <Select
                        onSelect={ val => fillValuesWithWebinar(val as number) }
                        options={ webinars.map(w => ({ label: w.name, value: w.webinar_id })) }
                    />
                </Form.Item>
            ) }

            <hr/>

            <Form.Item
                label="Nombre"
                name="name"
                rules={ !isWebinar ? rules.name : [] }
            >
                <Input disabled={ isWebinar } placeholder="¿Cual es el nombre del evento?"/>
            </Form.Item>

            <Form.List name="schedules">
                { (fields, { add, remove }) => (
                    <Form.Item
                        label={
                            <div>
                                <span>Horarios</span>
                                <Button disabled={ isWebinar } icon={ <PlusOutlined/> } onClick={ () => add() }/>
                            </div>
                        }
                    >
                        { form.getFieldError("schedules").length > 0 && (
                            <Alert type="error" showIcon message={ form.getFieldError("schedules")[0] }/>
                        ) }

                        { fields.map(field => (
                            <Form.Item
                                key={ field.key }
                                className="starts_at_cont"
                                validateStatus={
                                    form.getFieldError(["schedules", field.key, "full_date"]).length > 0 ? "error" :
                                        scheduleHasAttendances(event.schedules, field.key) ? "warning" : ""
                                }
                                help={
                                    scheduleHasAttendances(event.schedules, field.key)
                                        ? "El horario no puede ser eliminar porque tiene asistencias asociadas"
                                        : form.getFieldError(["schedules", field.key, "full_date"])[0]
                                }
                            >
                                <Form.Item name={ [field.name, 'full_date'] } style={ { display: "none" } }>
                                    <Input type="hidden"/>
                                </Form.Item>

                                <Form.Item
                                    { ...field }
                                    key={ `${ field.key }-date` }
                                    name={ [field.name, 'date'] }
                                    rules={ [{ required: true, message: "Debes ingresar una fecha" }] }
                                >
                                    <DatePicker
                                        disabled={ isWebinar || scheduleHasAttendances(event.schedules, field.key) }
                                        locale={ locale }
                                        format="DD-MM-yyyy"
                                        placeholder="DD-MM-YYYY"
                                    />
                                </Form.Item>

                                <Form.Item
                                    { ...field }
                                    key={ `${ field.key }-time` }
                                    name={ [field.name, 'time'] }
                                    rules={ [{ required: true, message: "Debes ingresar una hora" }] }
                                >
                                    <TimePicker
                                        disabled={ isWebinar || scheduleHasAttendances(event.schedules, field.key) }
                                        locale={ locale }
                                        use12Hours
                                        format="hh:mm A"
                                        placeholder="00:00 AM"
                                    />
                                </Form.Item>

                                <Button
                                    danger
                                    disabled={ isWebinar || scheduleHasAttendances(event.schedules, field.key) }
                                    icon={ <MinusCircleOutlined/> }
                                    onClick={ () => remove(field.name) }
                                />
                            </Form.Item>
                        )) }
                    </Form.Item>
                ) }
            </Form.List>

            <Form.Item
                label="Descripción"
                name="description"
                rules={ !isWebinar ? rules.description : [] }
            >
                <Input.TextArea disabled={ isWebinar } placeholder="Escribe una descripción de que trata el evento"/>
            </Form.Item>

            <Form.Item label="Imagen">
                <Form.Item
                    name="image"
                    valuePropName="fileList"
                    getValueFromEvent={ normFile }
                    noStyle
                    rules={ rules.image }
                >
                    <Upload.Dragger
                        accept="image/*"
                        name="image"
                        beforeUpload={ (file, FileList) => handleFiles(form, file, FileList, src => setImgSrc(src)) }
                        showUploadList={ false }
                    >
                        { imgSrc !== null && <img className="custom-preview-img" src={ imgSrc } alt="Preview"/> }
                        { imgSrc === null && (
                            <div>
                                <p className="ant-upload-drag-icon">
                                    <UploadOutlined/>
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                            </div>
                        ) }
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item>

            <Form.Item
                label="Link del stream"
                name="stream_link"
                rules={ !isWebinar ? rules.stream_link : [] }
            >
                <Input
                    disabled={ isWebinar }
                    placeholder="Pega la URL del evento. Puede ser un link de Facebook u otra platafoma"
                />
            </Form.Item>

            <Form.Item
                label="Link del vídeo"
                name="video_link"
                rules={ !isWebinar ? rules.video_link : [] }
            >
                <Input
                    disabled={ isWebinar }
                    placeholder="Pega la URL del evento. Puede ser un link de Facebook u otra platafoma"
                />
            </Form.Item>

            <Form.Item
                label="Archivos de apoyo"
                noStyle
            >
                <Form.List
                    name="attachments"
                >
                    { (fields, operation) =>
                        <AttachmentActions form={ form } operation={ operation } fields={ fields } name="files"/>
                    }
                </Form.List>
            </Form.Item>

            <Form.Item className="form-item-submit-button-cont">
                <Button
                    icon={ <UploadOutlined/> }
                    loading={ loading }
                    type="primary"
                    htmlType="submit"
                    size="large"
                >
                    Publicar
                </Button>
            </Form.Item>
        </Form>
    )
}

export default EditEventForm;