import Rest from "../Rest";
import IEvent from "../../interfaces/models/IEvent";
import config from "../client-configurations/AppServiceConfig";
import {AxiosError, AxiosResponse} from "axios";
import IWebinar from "../../interfaces/models/IWebinar";
import IApiResponse from "../../interfaces/api/IApiResponse";

class EventsService extends Rest<IEvent> {
    constructor() {
        super("events", config);
    }

    public async getWebinars(not_registered: boolean = false): Promise<AxiosResponse<IWebinar[]> | AxiosError> {
        try {
            return await this.http.get<IWebinar[]>(`${this.entityName}/webinars`, {
                params: {not_registered}
            });
        } catch (e) {
            return e as AxiosError;
        }
    }

    public async storePassedEvent(data: FormData): Promise<AxiosResponse<IApiResponse> | AxiosError> {
        try {
            return await this.http.post(`${this.entityName}/passed`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        } catch (e) {
            return e as AxiosError;
        }
    }
}

export default new EventsService();