import React, {useEffect, useState} from "react";
import {Alert, Button, Layout, Modal, Pagination, Spin} from "antd";
import "../../styles/views/Private/Home.less";
import IEvent from "../../interfaces/models/IEvent";
import EventsService from "../../api/services/EventsService";
import helpers from "../../helpers";
import EventCard from "../../components/EventCard";
import {useSelector} from "react-redux";
import IAppState from "../../interfaces/store/IAppState";
import NewEventForm from "../../components/Admin/NewEventForm";
import {AxiosError, AxiosResponse} from "axios";
import NewPassedEventForm from "../../components/Admin/NewPassedEventForm";
import EditEventForm from "../../components/Admin/EditEventForm";
import moment from "moment";
import IPaginatedApiData from "../../interfaces/helpers/IPaginatedApiData";

const Home: React.FunctionComponent = () => {
    //Events
    const [events, setEvents] = useState<IPaginatedApiData<IEvent>>({
        data: [],
        total: 0,
        current_page: 1
    });

    const [eventsError, setEventsError] = useState(false);
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    //Modals
    const [mdlNewEventVisible, setMdlNewEventVisible] = useState(false);
    const [mdlNewVideoVisible, setMdlNewVideoVisible] = useState(false);

    //State
    const isAuthenticated = useSelector<IAppState, boolean>(({auth}) => auth.isAuthenticated);
    const prevIsAuthenticated = helpers.usePrevious<boolean>(isAuthenticated);

    //Aux
    const [rerenderForm, setRerenderForm] = useState(true);
    const [authChecked, setAuthChecked] = useState(false);

    useEffect(() => {
        if (isAuthenticated && isAuthenticated !== prevIsAuthenticated) {
            setAuthChecked(true);
        }
    }, [isAuthenticated, prevIsAuthenticated]);

    useEffect(() => {
        if (authChecked) {
            getEvents(currentPage).then();
        }
    }, [authChecked, currentPage]);

    const getEvents = async (page: number) => {
        setLoadingEvents(true);
        const res = await EventsService.fetch(page);

        if (helpers.isAxiosError(res)) {
            const err = res as AxiosError;
            helpers.handleAxiosError(err, {
                default: () => setEventsError(true)
            })
        } else {
            const {data} = res as AxiosResponse<IPaginatedApiData<IEvent>>;

            data.data = data.data.sort((a, b) => {
                if (a.schedules === undefined || a.schedules.length === 0) {
                    return 1;
                } else if (b.schedules === undefined || b.schedules.length === 0) {
                    return -1;
                }

                const a_date = helpers.sortSchedules(a.schedules)[0].date;
                const b_date = helpers.sortSchedules(a.schedules)[0].date;

                if (a_date === null) {
                    return 1;
                } else if (b_date === null) {
                    return -1;
                }

                const a_schedule = moment(a_date, helpers.backDateFormat);
                const b_schedule = moment(b_date, helpers.backDateFormat);

                if (a_schedule === b_schedule) {
                    return 0;
                } else {
                    return a_schedule > b_schedule ? -1 : 1;
                }
            }).reverse();

            setEvents(data);
        }
        setLoadingEvents(false);
    }

    const onMdlClose = (value: boolean, callback: React.Dispatch<React.SetStateAction<boolean>>) => callback(value);

    const handleCreateEventSuccess = () => {
        setMdlNewEventVisible(false);
        getEvents(currentPage).then();
        setRerenderForm(false);
        setRerenderForm(true);
    }

    return (
        <Layout id="admin-home-component">
            <div className="actions-cont">
                <Button size="large" onClick={() => setMdlNewVideoVisible(true)}>Subir Grabación de Evento</Button>
                <Button type="primary" size="large" onClick={() => setMdlNewEventVisible(true)}>Crear Evento</Button>
            </div>

            <h1>Tus eventos</h1>

            <Spin spinning={loadingEvents} size="large">
                <div id="events-cont" className={events.data.length === 0 ? 'empty' : ''}>
                    {!eventsError && events.data.length === 0 && (
                        <div id="no-events">
                            <h1>Crea tu primer evento</h1>
                            <p>O sube una grabación de un evento</p>
                        </div>
                    )}

                    {!eventsError && events.data.map(e => (
                        <EventCard
                            key={e.id}
                            event={e}
                            mdlDetails={props => {
                                return <EditEventForm {...props}/>
                            }}
                            mdlDetailsClassName="__admin-form-mdl"
                            disableWrapperClass
                            onSuccess={() => getEvents(currentPage).then()}
                            detailsText={{
                                stream: "Editar",
                                video: "Editar"
                            }}
                        />
                    ))}

                    {eventsError && <Alert
                        type="error"
                        showIcon
                        message="Ha ocurrido un inconveniente al obtener los eventos, inténtalo más tarde..."
                    />}
                </div>

                <div className="pagination-cont">
                    <Pagination
                        hideOnSinglePage
                        current={currentPage}
                        onChange={page => setCurrentPage(page)}
                        total={events.total}
                        pageSize={6}
                    />
                </div>
            </Spin>

            <Modal
                visible={mdlNewEventVisible}
                onCancel={() => onMdlClose(false, setMdlNewEventVisible)}
                footer={null}
                className="__admin-form-mdl"
            >
                {rerenderForm && (
                    <NewEventForm onSuccess={handleCreateEventSuccess}/>
                )}
            </Modal>

            <Modal
                visible={mdlNewVideoVisible}
                onCancel={() => onMdlClose(false, setMdlNewVideoVisible)}
                footer={null}
                className="__admin-form-mdl"
            >
                <NewPassedEventForm/>
            </Modal>
        </Layout>
    )
}

export default Home;