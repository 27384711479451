import {logOut} from "../../store/actions/auth";
import {Dispatch} from "redux";
import IMenuItem from "../../interfaces/helpers/IMenuItem";

type getMenuItemsType = (dispatch: Dispatch<any>) => Array<IMenuItem>;

const getMenuItems: getMenuItemsType = (dispatch) => [
    {
        id: "section-3-menu-link",
        ref: "section-3",
        title: "Eventos",
        type: "ALL",
        onlyIn: "/"
    },
    {
        id: "next-event-menu-link",
        ref: "next-event",
        title: "Evento próximo",
        type: "ALL",
        onlyIn: "/"
    },
    {
        id: "section-4-menu-link",
        ref: "section-4",
        title: "Eventos pasados",
        type: "ALL",
        onlyIn: "/"
    },
    // {
    //     id: "/login",
    //     title: "Iniciar Sesión",
    //     path: "/login",
    //     type: "PUBLIC"
    // },
    {
        id: "/admin",
        title: "Admin",
        path: "/admin",
        type: "PRIVATE"
    },
    {
        id: "logout-menu-link",
        title: "Cerrar sesión",
        path: "",
        type: "PRIVATE",
        onClick: () => dispatch(logOut())
    }
]

export default getMenuItems;